import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import ErrorPage from '../components/error/Error';
import * as actions from '../redux/actions/appAction';
import EmailVerifyOutput from '../components/forms/EmailVerifyOutput';
import { queryToObject } from '../utils/generic';
import {
  checkActionCode,
  applyActionCode,
} from '../components/firebase/helper';
import { verifyEmail } from '../api';
import AuthLayout from '../components/layout/AuthLayout';
import TrustedCompaniesList from '../components/common/TrustedCompaniesList';
import EmailVerifiedBackdrop from '../components/NewForms/EmailVerifiedBackdrop';

const useStyles = makeStyles({
  w100: {
    width: '100%',
  },
});

const EmailVerify = props => {
  const classes = useStyles();
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(
    'Oops! It looks like the confirmation link has expired or is invalid. Request you to please login to the portal and resend the email verification link again.'
  );
  const [showVerifyEmail, setShowVerifyEmail] = useState(false);
  const [showVerifySecondaryEmail, setShowVerifySecondaryEmail] =
    useState(false);
  const [redirectURL, setRedirectUrl] = useState('');
  const [code, setCode] = useState('');
  const [redirectButtonTitle, setRedirectButtonTitle] =
    useState('Proceed to Login');
  const mode = props.match.params.mode;

  useEffect(() => {
    let params = queryToObject(window.location.search);
    const code = params['oobCode'];
    const verifMode = params.mode;
    let continueUrl = params['continueUrl'];
    if (continueUrl) {
      setRedirectUrl(continueUrl);
    }
    // const email = params['email'];
    if (verifMode !== 'verifySecondaryEmail') {
      if (!code) {
        setErrorMessage('INVALID EMAIL VERIFICATION LINK!');
        return setShowError(true);
      }
      setCode(code);
      props.startPageLoader();
      (async () => {
        try {
          await checkActionCode(code);
          await applyActionCode(code);
          await verifyEmail();
          setShowVerifyEmail(true);

          // await signInWithEmailLink(email);
          // setShowVerifyEmail(true)
          props.stopPageLoader();
        } catch (error) {
          setShowError(true);
          props.stopPageLoader();
        }
      })();
    } else {
      setShowVerifySecondaryEmail(true);
    }
  }, []);

  const verifySeconadaryEmail = async data => {
    try {
      props.startPageLoader();
      await verifyEmail(data);
      setShowVerifyEmail(true);
      props.stopPageLoader();
    } catch (error) {
      if (error?.code === 'EXPIRED') {
        setRedirectButtonTitle('Proceed to Login');
        setErrorMessage(error?.message);
      }

      // const errData = error?.response?.data;
      // if(errData?.error || errData?.message){
      //   setErrorMessage(errData?.error || errData?.message)
      // }
      setShowError(true);
      props.stopPageLoader();
    }
  };

  return (
    <>
      {showError && (
        <>
          <EmailVerifiedBackdrop
            header={true}
            {...props}
            heading="Link expired or invalid!"
            subHeading={errorMessage}
            btnText={redirectButtonTitle}
            onContinueClick={
              redirectButtonTitle && redirectURL && window.open(redirectURL)
            }
            icon={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="130"
                height="105"
                fill="none"
                viewBox="0 0 130 105"
              >
                <path fill="#fff" d="M0 0.5H130V104.5H0z"></path>
                <path
                  fill="#FAEEED"
                  fillRule="evenodd"
                  d="M32.76 70.18h47.32c.268 0 .529-.029.78-.084.251.055.512.084.78.084h27.04a3.64 3.64 0 000-7.28h-3.12a3.64 3.64 0 010-7.28h9.88a3.64 3.64 0 000-7.28H104a3.64 3.64 0 000-7.28H70.72a3.64 3.64 0 000-7.28H41.08a3.64 3.64 0 000 7.28h-20.8a3.64 3.64 0 000 7.28h13a3.64 3.64 0 010 7.28h-20.8a3.64 3.64 0 000 7.28h20.28a3.64 3.64 0 000 7.28zm84.76 0a3.64 3.64 0 100-7.28 3.64 3.64 0 000 7.28z"
                  clipRule="evenodd"
                ></path>
                <path
                  stroke="#FAEEED"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2.5"
                  d="M89.7 23.9v3.12M89.7 31.18v3.12M84.5 29.1h3.12M91.78 29.1h3.12M46.54 77.46v2.08M46.54 83.7v2.08M42.38 81.62h2.08M48.62 81.62h2.08"
                ></path>
                <path
                  fill="#FAEEED"
                  d="M86.84 54.9c-.149.004-.298.005-.447.005-13.132 0-23.885-10.238-24.785-23.205C51.154 33.349 43.16 42.44 43.16 53.407c0 12.135 9.79 21.973 21.865 21.973 11.576 0 21.05-9.04 21.815-20.48z"
                ></path>
                <path
                  fill="#fff"
                  fillRule="evenodd"
                  d="M57.628 33.995c-1.014.38-2.004.843-2.961 1.387a21.063 21.063 0 00-5.665 4.722m-1.43 1.912a20.638 20.638 0 00-1.65 3.01"
                  clipRule="evenodd"
                ></path>
                <path
                  fill="#FAEEED"
                  d="M58.068 35.165a1.25 1.25 0 10-.879-2.34l.879 2.34zm-3.401.217l.618 1.086-.618-1.086zm-4.18 3.133l-.869-.9.869.9zm-2.441.784a1.25 1.25 0 001.913 1.61l-1.913-1.61zm.569 3.407a1.25 1.25 0 00-2.085-1.38l2.085 1.38zm-2.057 1.015l1.103.587-1.103-.587zm-1.779.798a1.25 1.25 0 102.285 1.016l-2.285-1.016zm12.41-11.694a21.934 21.934 0 00-3.14 1.47l1.236 2.173c.899-.511 1.83-.945 2.783-1.303l-.879-2.34zm-3.14 1.47a22.112 22.112 0 00-4.43 3.32l1.736 1.799a19.611 19.611 0 013.93-2.946l-1.236-2.173zm-4.43 3.32a22.328 22.328 0 00-1.573 1.684l1.913 1.61a19.8 19.8 0 011.396-1.495l-1.737-1.799zm-3.09 3.71c-.389.59-.747 1.193-1.075 1.81l2.207 1.173c.29-.546.608-1.08.954-1.602l-2.085-1.38zm-1.075 1.81c-.242.455-.467.916-.675 1.384l2.285 1.016c.184-.415.383-.824.597-1.227l-2.207-1.174z"
                ></path>
                <path
                  fill="#EE6157"
                  d="M64.954 79.5a25.864 25.864 0 01-12.707-3.321 1.53 1.53 0 111.502-2.667c8.927 5.027 20.226 3.47 27.48-3.783 8.95-8.949 8.95-23.51 0-32.458-8.948-8.949-23.51-8.95-32.459 0-8.948 8.949-8.948 23.51 0 32.458a1.53 1.53 0 01-2.164 2.165c-10.142-10.143-10.142-26.645 0-36.788 10.142-10.142 26.646-10.142 36.788 0 10.141 10.143 10.141 26.645 0 36.788-4.996 4.995-11.688 7.606-18.44 7.606z"
                ></path>
                <path
                  fill="#EE6157"
                  d="M73.125 63.656c-.391 0-.783-.15-1.082-.448L55.792 46.956a1.53 1.53 0 012.164-2.164l16.251 16.251a1.53 1.53 0 01-1.082 2.613z"
                ></path>
                <path
                  fill="#EE6157"
                  d="M56.874 63.656a1.53 1.53 0 01-1.082-2.612l16.251-16.252a1.53 1.53 0 112.164 2.164L57.956 63.209c-.3.299-.69.448-1.082.448z"
                ></path>
              </svg>
            }
            showContactInfo={true}
          />
        </>
      )}
      {showVerifyEmail && (
        <AuthLayout
          left={<TrustedCompaniesList />}
          right={
            <EmailVerifyOutput
              {...props}
              mode={mode}
              code={code}
              redirectURL={redirectURL}
            />
          }
        />
      )}
      {showVerifySecondaryEmail && (
        <AuthLayout
          left={<TrustedCompaniesList />}
          className={classes.w100}
          right={
            <EmailVerifyOutput
              {...props}
              onSubmit={verifySeconadaryEmail}
              isSecondaryEmail
            />
          }
        />
      )}
    </>
  );
};

const mapStateToProps = state => ({
  ...state,
});
const mapDispatchToProps = {
  ...actions,
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(EmailVerify)
);
