import React from 'react';
import {
  FormControlLabel,
  FormGroup,
  Checkbox,
  FormHelperText,
  styled,
  Box,
} from '@material-ui/core';

import FieldGridWrapper from '../FieldGridWrapper';
import StyledFormControl from '../StyledFormControl';

const StyledCheckbox = styled(Checkbox)(({ theme }) => ({
  paddingTop: 0,
  paddingBottom: 0,
  gap: theme.spacing(6),
}));

const CheckboxGroupInput = ({
  label,
  value,
  required,
  options,
  onChange,
  row,
  fullWidth = true,
  disabled,
  error,
  gridLayout,
  helperText,
  errorText,
  labelWidth = '100%',
  ...rest
}) => {
  const _onChange = (event, checked) => {
    const castedEvent = event;
    const newVal = checked
      ? [...value, castedEvent.target.value]
      : value.filter(val => val !== castedEvent.target.value);
    if (onChange)
      onChange({
        target: {
          name: rest.name || '',
          value: newVal,
        },
      });
  };

  return (
    <StyledFormControl
      fullWidth={fullWidth}
      disabled={disabled}
      required={required}
      error={error}
      variant="standard"
    >
      <FieldGridWrapper label={label} gridLayout={gridLayout}>
        <FormGroup row={row}>
          {options.map(option => (
            <Box key={option.value} width={labelWidth}>
              <FormControlLabel
                {...rest}
                label={option.label}
                onChange={_onChange}
                control={
                  <StyledCheckbox
                    color="primary"
                    checked={value.includes(option.value)}
                    required={required}
                    size="small"
                  />
                }
                value={option.value}
              />
              {option?.helperText && (
                <FormHelperText error={false}>
                  {option.helperText}
                </FormHelperText>
              )}
            </Box>
          ))}
        </FormGroup>
        {helperText && (
          <FormHelperText error={false}>{helperText}</FormHelperText>
        )}
        {errorText && <FormHelperText>{errorText}</FormHelperText>}
      </FieldGridWrapper>
    </StyledFormControl>
  );
};

CheckboxGroupInput.defaultProps = {
  row: true,
};

export default CheckboxGroupInput;
