import {
  START_LOADER,
  START_PAGE_LOADER,
  STOP_PAGE_LOADER,
  STOP_LOADER,
  SHOW_SNACKBAR,
  HIDE_SNACKBAR,
} from '../actions/actionTypes';

var loaderCount = 0;
const counterReducer = (
  state = {
    apiLoading: false,
    pageLoading: false,
    snackbarVisible: false,
    snackbarMessage: '',
  },
  action
) => {
  switch (action.type) {
    case START_LOADER:
      loaderCount++;
      return {
        ...state,
        apiLoading: true,
      };
    case STOP_LOADER:
      loaderCount--;
      if (loaderCount <= 0) {
        loaderCount = 0;
        return {
          ...state,
          apiLoading: false,
        };
      }
      return state;
    case START_PAGE_LOADER:
      return {
        ...state,
        pageLoading: true,
      };
    case STOP_PAGE_LOADER:
      return {
        ...state,
        pageLoading: false,
      };
    case SHOW_SNACKBAR:
      return {
        ...state,
        snackbarMessage: action.message,
        snackbarVisible: true,
      };
    case HIDE_SNACKBAR:
      return {
        ...state,
        snackbarVisible: false,
      };
    default:
      return { ...state };
  }
};
export default counterReducer;
