import React from 'react';
import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
  styled,
} from '@material-ui/core';

const StyledList = styled(List)(({ theme }) => ({
  listStyle: 'disc',
  marginLeft: theme.spacing(4),
}));

const StyledListItem = styled(ListItem)({
  display: 'list-item',
  paddingLeft: 0,
});

const LeftGrid = () => {
  return (
    <>
      <Box>
        <Typography variant="h3">Hire and expand globally.</Typography>
        <Typography variant="body1">
          <em>It's a breeze.</em>
        </Typography>
      </Box>
      <Box>
        <StyledList>
          <StyledListItem>
            <ListItemText
              primary={
                <>
                  Hire employees and contractors in <b>160+ countries</b>
                </>
              }
            />
          </StyledListItem>
          <StyledListItem>
            <ListItemText
              primary={
                <>
                  Make <b>cross-border payments</b> in 100+ currencies
                </>
              }
            />
          </StyledListItem>
          <StyledListItem>
            <ListItemText
              primary={
                <>
                  Stay <b>100% compliant</b> with local employment laws
                </>
              }
            />
          </StyledListItem>
        </StyledList>
      </Box>
    </>
  );
};

export default LeftGrid;
