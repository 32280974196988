import React from 'react';
import ilus from '../../images/WebsiteCrash.svg';
import Button from '@material-ui/core/Button';
import Hidden from '@material-ui/core/Hidden';

export default function ErrorPage500(props) {
  return (
    <React.Fragment>
      <div className="sk-404-main-ctnr">
        <div className="sk-404-ttl">
          <Hidden smDown>"Aw, Snap!" Page Crashes</Hidden>
          <Hidden mdUp>
            <div>"Aw, Snap!"</div>
            <div>Page Crashes</div>
          </Hidden>
        </div>

        <div className="sk-404-ilus-ctnr">
          <img src={ilus} className="sk-404-ilus" alt="404" />
        </div>
        <div className="sk-404-btn-ctnr">
          <a
            onClick={() => {
              props.history.goBack();
            }}
          >
            <Button variant="outlined" className="sk-404-btn">
              Back To Login
            </Button>
          </a>
        </div>
      </div>
    </React.Fragment>
  );
}
