import React from 'react';
import {
  Grid,
  Card,
  CardContent,
  Typography,
  Divider,
  Link,
  Box as MuiBox,
  styled,
} from '@material-ui/core';
import StandardTextField from '../../designSystem/StandardTextField';
import AuthenticationCTAs from '../../common/AuthenticationCTAs';

const bottomHelperText = (
  <Typography variant="body2" align="center">
    Having trouble logging in?{' '}
    <Link href="mailto:contact@skuad.io" id="form-contactSupportLink">
      Contact Support
    </Link>
  </Typography>
);

const TopPaddedBox = styled(MuiBox)(({ theme }) => {
  return {
    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(12),
    },
  };
});

const RightGrid = ({
  state,
  onChangeHandler,
  onSubmit,
  onResetPasswordButtonProps,
  onCaptchaClickHandler,
}) => {
  return (
    <TopPaddedBox pt={12} id="form-forgotPassword">
      <Card>
        <CardContent>
          <form noValidate onSubmit={onSubmit}>
            <Grid container spacing={5}>
              <Grid item xs={12}>
                <Typography variant="subtitle1">
                  Enter your email below to receive your password reset
                  instructions
                </Typography>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <StandardTextField
                  id="form-forgotPassword-emailInput"
                  label="Your Email ID"
                  name="email"
                  type="email"
                  required
                  value={state.formData.email}
                  onChange={onChangeHandler}
                  error={!!state.errorData.email}
                  helperText={state.errorData.email}
                />
              </Grid>
              <AuthenticationCTAs
                onCaptchaClickHandler={onCaptchaClickHandler}
                onResetPasswordButtonProps={onResetPasswordButtonProps}
                bottomHelperText={bottomHelperText}
              />
            </Grid>
          </form>
        </CardContent>
      </Card>
    </TopPaddedBox>
  );
};

export default RightGrid;
