import React, { forwardRef } from 'react';
import {
  Button as MuiButton,
  CircularProgress,
  Box,
  styled,
} from '@material-ui/core';

const StyledButton = styled(MuiButton, {
  shouldForwardProp: prop => prop !== 'disableGutters',
})(({ theme, disableGutters }) => ({
  ...(disableGutters && {
    padding: `0 ${theme.spacing(1)}`,
    minWidth: 'auto',
    minHeight: 'auto',
  }),
}));

const Button = forwardRef(({ children, disabled, loading, ...rest }, ref) => (
  <StyledButton
    color="primary"
    {...rest}
    ref={ref}
    disabled={disabled || loading}
  >
    {loading && (
      <Box
        position="absolute"
        width="100%"
        height="100%"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <CircularProgress size={24} />
      </Box>
    )}
    {children}
  </StyledButton>
));

export default Button;
