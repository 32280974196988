import React from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { Box, Grid, Container, styled, Typography } from '@material-ui/core';
import Header from '../../common/Header';
import SignupUserSelectorBgImage from '../../../images/how-you-want-to-start.png';
import UserSelectionCard from './UserSelectionCard';
import TrustedCompaniesList from '../../common/TrustedCompaniesList';
import { updateQueryParam } from '../../../utils/generic';
import { USER_TYPES } from '../../../utils/constants';

const BoxWithBackground = styled(Box)(({ theme }) => ({
  backgroundColor: '#FEFAFA',
  minHeight: '100vh',
  backgroundImage: `url(${SignupUserSelectorBgImage})`,
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'top',
  paddingBottom: theme.spacing(5),
}));

const StyledContainer = styled(Container)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  marginTop: theme.spacing(8),
  [theme.breakpoints.down('sm')]: {
    marginTop: theme.spacing(5),
  },
}));

const employerFeatures = [
  'Hire people to expand globally',
  'Hire people to expand globally',
  'Pay them in local currencies',
];
const contractorFeatures = [
  'Receive payments in multiple currencies',
  'Automated invoices, best FX rates',
];

const UserTypeSelector = () => {
  const location = useLocation();
  const history = useHistory();

  return (
    <BoxWithBackground>
      <Header />
      <StyledContainer maxWidth="lg">
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <UserSelectionCard
              title={
                <Typography>
                  As an <b>Employer</b>{' '}
                </Typography>
              }
              features={employerFeatures}
              description="Add Employees, Add Contractors, Run Payroll, Cost Calculator"
              button={{
                label: 'Start managing your global team',
                props: {
                  onClick: () => {
                    const queryParams = [
                      {
                        parameter: 'userType',
                        value: USER_TYPES.Client,
                      },
                    ];
                    history.push({
                      pathname: location.pathname,
                      search: updateQueryParam(queryParams, location.search),
                    });
                  },
                  id: 'form-signUp-clientStartCTA',
                },
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <UserSelectionCard
              title={
                <Typography>
                  As a <b>Contractor</b> your superpowers are to:
                </Typography>
              }
              features={contractorFeatures}
              description="Manage clients, timesheets, invoices, cross border payments - all in one dashboard"
              button={{
                label: 'Start as a contractor',
                props: {
                  onClick: () => {
                    const queryParams = [
                      {
                        parameter: 'userType',
                        value: USER_TYPES.Contractor,
                      },
                    ];
                    history.push({
                      pathname: location.pathname,
                      search: updateQueryParam(queryParams, location.search),
                    });
                  },
                  id: 'form-signUp-contractorStartCTA',
                },
              }}
            />
          </Grid>
        </Grid>
        <TrustedCompaniesList
          headingProps={{
            variant: 'subtitle1',
            fontWeight: 'fontWeightRegular',
          }}
          wrapperBoxProps={{
            marginTop: 3,
          }}
          itemGridProps={{
            xs: 6,
            sm: 4,
            lg: 2,
          }}
          showLogoOnDashBoard={false}
        />
      </StyledContainer>
    </BoxWithBackground>
  );
};

export default UserTypeSelector;
