import React from 'react';
import { withStyles, Tooltip, makeStyles } from '@material-ui/core';
import { ReactComponent as ToolTipIcon } from '../../images/auth/tooltip.svg';

const HtmlTooltip = withStyles(theme => ({
  tooltip: {
    border: '1px solid #DDE1EB',
    boxSizing: 'border-box',
    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
    borderRadius: '5px',
    padding: '15px',
    backgroundColor: '#fff',
    fontSize: '12px',
    lineHeight: '16px',
    color: '#000000',
    textAlign: 'center',
  },
}))(Tooltip);

const useStyles = makeStyles({
  icon: {
    cursor: 'pointer',
    marginLeft: '11.33px',
    marginTop: '0px',
  },
});

const CustomTooltip = ({ text }) => {
  const classes = useStyles();
  return (
    <HtmlTooltip title={<>{text}</>}>
      <ToolTipIcon className={classes.icon} />
    </HtmlTooltip>
  );
};

export default CustomTooltip;
