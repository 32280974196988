export const MIN_PASSWORD_LENGTH = 10;
export const GENERIC_EMAIL_LABEL = 'What is your email?';
export const COMPANY_EMAIL_LABEL = 'What is your official email?';
export const GENERIC_EMAIL_PLACEHOLDER = 'E.g. you@email.com';
export const COMPANY_EMAIL_PLACEHOLDER = 'E.g. you@company.com';
export const PASSWORD_SUGGESTION_MESSAGE =
  'Minimum of 10 characters, uppercase and lowercase letters, a number and a special character';

export const ERROR_MESSAGES = {
  name: 'Name is required',
  email: 'Email is required',
  empty_password: 'Password is required',
  invalid_password: `Password must be greater than ${
    MIN_PASSWORD_LENGTH - 1
  } characters`,
  invalid_confirm_password: 'Password must be equal to Confirm Password',
  invalid_email: 'Please enter a valid email',
  email_not_exist: "Email doesn't exist on the platform",
  phone: 'Mobile number is required',
  invalid_phone: 'Invalid mobile number',
  invalid_otp: 'Invalid OTP',
  otp: 'Fill OTP first',
  tc_and_privicy_policy: 'Please read and accept Terms and Privacy Policy',
  uppercase_character: 'Password must contain an uppercase character',
  lowercase_character: 'Password must contain a lowercase character',
  numeric_character: 'Password must contain a number',
  special_character: 'Password must contain a special character',
  no_option: 'Please select an option',
  positive_number: 'Please enter a positive number',
  onboard_contractor: 'Please add atleast one contractor',
  onboard_employee: 'Please add atleast one employee',
  onboard_contractorsAndEmployees: 'Please add atleast one employee/contractor',
  valid_numeric_value: 'Please enter a valid numeric value',
  companyName: 'Company Name is required',
  workEmail: 'Please enter your company/work email',
};

export const FIREBASE_ERROR_MESSAGES = {
  'auth/user-not-found': 'The username or password is incorrect',
  'auth/wrong-password': 'The username or password is incorrect',
  'auth/popup-closed-by-user': 'Request Cancelled by User',
  'auth/network-request-failed': 'Request timed out',
  'auth/account-exists-with-different-credential':
    'Account already exists with same email',
  'auth/email-already-in-use':
    'The email address is already in use by another account',
  'auth/invalid-verification-code': 'The OTP entered is incorrect',
  "auth/internal-error": "Two many attempts try again later"
};

export const LOGIN_MODES = {
  hire: {
    signup: true,
    modes: ['linkedin', 'mobile'],
  },
  admin: {
    signup: false,
    modes: ['google'],
  },
  pay: {
    signup: false,
    modes: ['google'],
  },
  company: {
    signup: true,
    modes: ['mobile'],
  },
};

export const USER_TYPE = {
  Employer: 'EMPLOYER',
  Contractor: 'CONTRACTOR',
  Employee: 'EMPLOYEE',
};

export const USER_ROLE = {
  Individual: 'Individual',
  Company: 'Company',
};

export const ScreenType = {
  SignUp: 'signup',
  SignIn: 'signin',
  ForgotPassword: 'forgotPassword',
  UserRole: 'selectRole',
  AdditionalInfo: 'additionalInfo',
};

export const CLIENT_PLAN = {
  Contractors: 'contractors',
  Employees: 'employees',
  ContractorsAndEmployees: 'contractorsAndEmployees',
};

/* NEW CONSTANTS */

export const USER_TYPE_MAPPING = {
  Employer: 'EMPLOYER',
  Contractor: 'CONTRACTOR',
  Employee: 'EMPLOYEE',
};

export const SCREEN_TYPES = {
  Signin: 'signin',
  Signup: 'signup',
  ForgotPassword: 'forgotPassword',
};

export const USER_TYPES = {
  Client: 'client',
  Contractor: 'contractor',
  Employee: 'employee',
};

export const USER_ROLES = {
  Individual: 'individual',
  Company: 'company',
};

export const validations = {
  email: {
    required: {
      message: 'Email address is required',
      value: true,
    },
    patterns: [
      {
        message: 'Please enter valid email',
        validator: new RegExp(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        ),
      },
    ],
  },
  password: {
    required: {
      message: 'Password is required',
      value: true,
    },
    patterns: [
      // {
      //   message:
      //     'Password should consist minimum 8 character with minimum of 1 lowercase, 1 uppercase and a symbol',
      //   validator: new RegExp(
      //     /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/
      //   ),
      // },
      {
        message: ERROR_MESSAGES.lowercase_character,
        validator: new RegExp(/[a-z]/),
      },
      {
        message: ERROR_MESSAGES.uppercase_character,
        validator: new RegExp(/[A-Z]/),
      },
      {
        message: ERROR_MESSAGES.numeric_character,
        validator: new RegExp(/[0-9]/),
      },
      {
        message: ERROR_MESSAGES.special_character,
        validator: new RegExp(/[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~`]/),
      },
    ],
    minLength: {
      message: 'Password must me miminum of 8 character length',
      value: 8,
    },
    associatedFieldEquality: {
      message: 'Both the password and confirm password should be same',
      associatedFieldEquality:
        'Confirm password should be same as password entered in previous field',
      value: 'confirmPassword',
    },
  },
  confirmPassword: {
    required: {
      message: 'Confirm Password is required',
      value: true,
    },
    matchWithKey: {
      message:
        'Confirm password should be same as password entered in previous field',
      value: 'password',
    },
  },
  fullName: {
    required: {
      message: 'Full name is required',
      value: true,
    },
  },
  companyName: {
    required: {
      message: 'Company name is required',
      value: true,
    },
  },
};
