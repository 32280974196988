import React from 'react';
import { Grid, Box, FormLabel } from '@material-ui/core';

const FieldGridWrapper = ({ gridLayout = true, label, children }) => {
  return gridLayout ? (
    <Grid container spacing={3} alignItems="flex-start">
      {label && (
        <Grid item xs={12} sm={5}>
          <FormLabel focused={false} error={false}>
            {label}
          </FormLabel>
        </Grid>
      )}
      <Grid item xs={12} sm={!label ? 12 : 7}>
        <Box display="inline-flex" flexDirection="column" width="100%">
          {children}
        </Box>
      </Grid>
    </Grid>
  ) : (
    <>
      {label && (
        <FormLabel focused={false} error={false}>
          {label}
        </FormLabel>
      )}
      {children}
    </>
  );
};

export default FieldGridWrapper;
