import React, { useEffect } from 'react';
import { withRouter, useLocation, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import Container from '../components/layout/Container';
import * as actions from '../redux/actions/appAction';
import { queryToObject } from '../utils/generic';
import { useState } from 'react';
import GoogleButton from '../components/forms/GoogleButton';
import NameEmailInput from '../components/forms/NameEmailInput';
import EmailInput from '../components/forms/EmailInput';
import MobileInput from '../components/forms/MobileInput';

const Login = props => {
  const location = useLocation();
  const history = useHistory();
  const [rootView, setRootView] = useState(null);
  const [mobileView, setMobileView] = useState(false);
  const [emailView, setEmailView] = useState(false);
  const [showOTP, setOTPView] = useState(false);
  const [token, setToken] = useState(false);
  const [firstSignup, setFirstSignup] = useState(true);
  let params = {};
  if (
    location.pathname.includes('allremote') &&
    window.location.search &&
    window.location.search.includes('redirect_url')
  ) {
    params['redirect_url'] = window.location.search
      ?.split('redirect_url')[1]
      .slice(1);
  } else {
    params = queryToObject(window.location.search);
  }
  const redirectURL = params['redirect_url'];

  useEffect(() => {
    const URL = window.location;
    let domain = URL.hostname;
    const domainArray = domain.split('.');
    let redirect_url = params['redirect_url'];
    domain = domainArray[1] + '.' + domainArray[2];

    if ((redirect_url && !redirect_url.includes(domain)) || !redirect_url) {
      redirect_url = URL.protocol + '//' + URL.hostname;
      if (URL.href.includes('allremote')) {
        if (domainArray[0].includes('auth-')) {
          redirect_url = redirect_url.replace('auth-', '');
        } else {
          redirect_url = redirect_url.replace('auth.', '');
        }
      } else {
        redirect_url = redirect_url.replace('auth', URL.pathname);
      }
      history.replace({
        pathname: location.pathname,
        search: `?redirect_url=${encodeURIComponent(redirect_url)}`,
      });
    }
  }, []);

  return (
    <Container {...props}>
      {(rootView == null || rootView == 'mobile') &&
        props.loginMode.modes.mobile && (
          <MobileInput
            {...props}
            redirectURL={redirectURL}
            setRootView={setRootView}
            setMobileView={setMobileView}
            setEmailView={setEmailView}
            setOTPView={setOTPView}
            showOTP={showOTP}
            rootView={rootView}
            setToken={setToken}
          />
        )}
      {(rootView == null || rootView == 'email') &&
        props.loginMode.modes.email && (
          <EmailInput
            {...props}
            redirectURL={redirectURL}
            setRootView={setRootView}
            setMobileView={setMobileView}
            setEmailView={setEmailView}
            setOTPView={setOTPView}
            showOTP={showOTP}
            rootView={rootView}
            setToken={setToken}
          />
        )}
      {rootView == null && Object.keys(props.loginMode.modes).length > 1 && (
        <>
          {' '}
          <div className="separator">OR</div>
        </>
      )}
      {rootView == null && props.loginMode.modes.google && (
        <>
          <GoogleButton
            {...props}
            redirectURL={redirectURL}
            setRootView={setRootView}
            setMobileView={setMobileView}
            setEmailView={setEmailView}
            setOTPView={setOTPView}
            showOTP={showOTP}
            rootView={rootView}
            setToken={setToken}
            firstSignup={firstSignup}
            setFirstSignup={setFirstSignup}
          />
        </>
      )}
      {emailView && (
        <NameEmailInput
          {...props}
          redirectURL={redirectURL}
          setRootView={setRootView}
          setMobileView={setMobileView}
          setEmailView={setEmailView}
          rootView={rootView}
          setToken={setToken}
        />
      )}
    </Container>
  );
};

const mapStateToProps = state => ({
  ...state,
});

const mapDispatchToProps = {
  ...actions,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Login));
