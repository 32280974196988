import React, { useEffect } from 'react';
import { signInWithPopup, getIdToken } from '../firebase/helper';
import { successRedirection } from '../common/helper';
import googleLogo from '../../images/google_icon.svg';
import Img from '../common/Img';

const GoogleButton = props => {
  useEffect(() => {
    if (!props.redirectURL) return;
  }, [props.redirectURL]);

  const handleConnectClick = async () => {
    props.startPageLoader();
    try {
      await signInWithPopup('google.com');

      await successRedirection(props);
      // props.stopPageLoader();
    } catch (error) {
      props.stopPageLoader();
      props.showSnackbar(error.message);
    }
  };

  return (
    <>
      <div
        className="gl-btn d-flex rounded position-relative"
        onClick={handleConnectClick}
      >
        <div className="my-auto div-img rounded-left position-absolute">
          <Img src={googleLogo} className="m-auto" />
        </div>
        <div className="m-auto ln-btn-text">Continue with Google</div>
      </div>
    </>
  );
};

export default GoogleButton;
