import { Box } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ErrorPage from '../components/error/InviteExpired';
import * as actions from '../redux/actions/appAction';
// import Layout from '../components/layout/Layout';
// import Container from '../components/layout/Container';
// import LOGIN_MODES from '../components/common/config';
import { queryToObject } from '../utils/generic';
import { verifyPasswordResetCode } from '../components/firebase/helper';
import PayAuthLayout from '../components/layout/PayAuthLayout';
import TrustedCompaniesList from '../components/common/TrustedCompaniesList/TrustedCompaniesList';
import ResetPasswordForm from '../components/modules/ResetPassword/ResetPasswordForm';

const ResetPassword = props => {
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(
    'RESET LINK HAS BEEN EXPIRED!'
  );
  const [showReset, setShowReset] = useState(false);
  const [redirect_url, setRedirectUrl] = useState('');
  const [code, setCode] = useState('');
  // const mode = props.match.params.mode;

  useEffect(() => {
    let params = queryToObject(window.location.search);
    let code = params['oobCode'];
    let continueUrl = params['continueUrl'];
    if (continueUrl) {
      setRedirectUrl(continueUrl);
    }
    if (!code) {
      setErrorMessage('INVALID PASSWORD RESET LINK!');
      return setShowError(true);
    }
    setCode(code);
    props.startPageLoader();
    (async () => {
      try {
        await verifyPasswordResetCode(code);
        setShowReset(true);
        props.stopPageLoader();
      } catch (error) {
        setShowError(true);
        props.stopPageLoader();
      }
    })();
  }, []);

  // const renderWithLayout = (component, mode) => {
  //   return (
  //     <Layout {...props} loginMode={mode}>
  //       {component}
  //     </Layout>
  //   );
  // };

  return (
    <>
      {showError && (
        <ErrorPage header={true} {...props} errMessage={errorMessage} />
      )}
      {showReset && (
        <PayAuthLayout
          LeftGridItem={<TrustedCompaniesList />}
          RightGridItem={
            <ResetPasswordForm
              code={code}
              redirect_url={redirect_url}
              props={props}
            />
          }
        />
      )}
    </>
  );
};

const mapStateToProps = state => ({
  ...state,
});
const mapDispatchToProps = {
  ...actions,
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ResetPassword)
);
