import React, { useEffect, useReducer } from 'react';
import Cookies from 'js-cookie';
import { useLocation, useHistory } from 'react-router-dom';
import queryString from 'query-string';
import PayAuthLayout from '../components/layout/PayAuthLayout';
import SignIn from '../components/NewForms/SignIn';
import CreateAccount from '../components/NewForms/CreateAccount';
import ForgotPassword from '../components/NewForms/ForgotPassword';
import UserTypeSelector from '../components/modules/Signup/UserTypeSelector';
import TrustedCompaniesList from '../components/common/TrustedCompaniesList';
import SignupLeftGrid from '../components/modules/Signup/LeftGrid';
import { checkIfEmailExist } from '../components/firebase/helper';
import { useDispatch } from 'react-redux';
import { startPageLoader, stopPageLoader } from '../redux/actions/appAction';

import {
  updateQueryParam,
  ObjectHasKey,
  getUserTypeByType,
  getUserRoleByType,
} from '../utils/generic';
import { USER_TYPES, SCREEN_TYPES } from '../utils/constants';

import {
  PayAuthInitialState,
  ActionTypes,
  reducer,
} from '../components/modules/PayAuth/reducer';

const testingEnv =
  process.env.REACT_APP_ENV === 'development' ||
  process.env.REACT_APP_ENV === 'staging';

const ipValidated = Cookies.get('ipValidation')?.split('|')[0] === 'true';

const ignoreCaptchaValidation = testingEnv || ipValidated;

const screenTypes = [
  SCREEN_TYPES.Signin,
  SCREEN_TYPES.Signup,
  SCREEN_TYPES.ForgotPassword,
];
const userTypes = [
  USER_TYPES.Client,
  USER_TYPES.Contractor,
  USER_TYPES.Employee,
];

const renderLeftPanel = screen => {
  switch (screen) {
    case 'signin':
      return <TrustedCompaniesList />;
    case 'signup':
      return <SignupLeftGrid />;
    case 'forgotPassword':
      return <TrustedCompaniesList />;
    default:
      return <TrustedCompaniesList />;
  }
};

const PayAuth = ({ inviteUser, ...props }) => {
  const location = useLocation();
  const history = useHistory();
  const queryParams = queryString.parse(location.search);
  const {
    invite_id: inviteId,
    redirect_url: redirectURL,
    screen,
    userType,
  } = queryParams;
  const { companyName, email, name, type } = inviteUser || {};

  const [state, dispatch] = useReducer(reducer, PayAuthInitialState);

  const getRedirectUrl = () => {
    const URL = window.location;
    if (URL.pathname === '/pay') {
      return process.env.REACT_APP_PAY_PLATFORM_URL;
    }
    let newRedirectURL = URL.protocol + '//' + URL.hostname;
    newRedirectURL = newRedirectURL.replace('auth', URL.pathname);
    return newRedirectURL;
  };

  useEffect(() => {
    // * This useEffect is responsible for setting queryParam `redirect_url` if not present in url
    const queryParams = [];
    const URL = window.location;
    let domain = URL.hostname;
    const domainArray = domain.split('.');
    domain = domainArray[1] + '.' + domainArray[2];
    if ((redirectURL && !redirectURL.includes(domain)) || !redirectURL) {
      queryParams.push({
        parameter: 'redirect_url',
        value: encodeURIComponent(getRedirectUrl()),
      });
    }
    // * set default screen to sign-in
    if (!screen || (screen && !screenTypes.includes(screen))) {
      queryParams.push({
        parameter: 'screen',
        value: screenTypes[0],
      });
    }
    // * set correct userType, is wrong one is present.
    if (userType && !userTypes.includes(userType)) {
      queryParams.push({
        parameter: 'userType',
        value: userTypes[0],
      });
    }
    history.replace({
      pathname: location.pathname,
      search: updateQueryParam(queryParams, location.search),
    });
  }, [screen, redirectURL]);

  const _dispatch = useDispatch();

  const verifyUserExistsInFirebase = async () => {
    _dispatch(startPageLoader());
    // * set invited to true, if invite object is present.
    dispatch({
      type: ActionTypes.UpdateInvited,
      payload: true,
    });
    dispatch({
      type: ActionTypes.UpdateUserType,
      payload: getUserTypeByType(type),
    });
    dispatch({
      type: ActionTypes.UpdateUserRole,
      payload: getUserRoleByType(getUserTypeByType(type)),
    });
    // * set state data from invite
    dispatch({
      type: ActionTypes.UpdateFormData,
      payload: {
        email,
        fullName: name,
        companyName,
      },
    });

    try {
      const email_exist = await checkIfEmailExist(inviteUser?.email);
      _dispatch(stopPageLoader());
      let queryParams = [];
      if (email_exist.length) {
        queryParams = [
          {
            parameter: 'screen',
            value: SCREEN_TYPES.Signin,
          },
        ];
      } else {
        queryParams = [
          {
            parameter: 'screen',
            value: SCREEN_TYPES.Signup,
          },
          {
            parameter: 'userType',
            value: getUserTypeByType(type),
          },
        ];
      }
      history.replace({
        pathname: location.pathname,
        search: updateQueryParam(queryParams, location.search),
      });
    } catch (err) {
      throw err;
    }
  };

  useEffect(() => {
    // * This useEffect is responsible for opening sign-up page, if invite is present
    if (ObjectHasKey(inviteUser)) {
      verifyUserExistsInFirebase();
    }
  }, []);

  const onChangeHandler = e => {
    if (state.errorData[e.target.name]) {
      // * If error key of any field has been created, remove it as user changes anything there, since user has started new input in that field
      dispatch({
        type: ActionTypes.DeleteErrorData,
        payload: e.target.name,
      });
    }
    if (
      // * If associatedFieldForValidation is present, remove it too (like here for password, confirmPassword should also change)
      state.errorData[
        e.target?.getAttribute?.('data-associatedFieldForValidation')
      ]
    ) {
      dispatch({
        type: ActionTypes.DeleteErrorData,
        payload: e.target?.getAttribute?.('data-associatedFieldForValidation'),
      });
    }
    dispatch({
      type: ActionTypes.UpdateFormData,
      payload: {
        [e.target.name]: e.target.value,
      },
    });

    if (screen === SCREEN_TYPES.Signin ? e.target.name !== 'password' : true) {
      dispatch({
        type: ActionTypes.UpdateErrorData,
        payload: e.target,
      });
    }
  };

  const onCaptchaClickHandler = value => {
    dispatch({
      type: ActionTypes.UpdateCaptchaToken,
      payload: value,
    });
  };

  const renderRightPanel = screen => {
    switch (screen) {
      case SCREEN_TYPES.Signin:
        return (
          <SignIn
            state={state}
            dispatch={dispatch}
            onChangeHandler={onChangeHandler}
            onCaptchaClickHandler={onCaptchaClickHandler}
            redirectURL={redirectURL}
            ignoreCaptchaValidation={ignoreCaptchaValidation}
            {...props}
          />
        );
      case SCREEN_TYPES.Signup:
        return (
          <CreateAccount
            state={state}
            dispatch={dispatch}
            onChangeHandler={onChangeHandler}
            onCaptchaClickHandler={onCaptchaClickHandler}
            invited={!!inviteId}
            redirectURL={redirectURL}
            testingEnv={testingEnv}
            ignoreCaptchaValidation={ignoreCaptchaValidation}
            {...props}
          />
        );
      case SCREEN_TYPES.ForgotPassword:
        return (
          <ForgotPassword
            state={state}
            dispatch={dispatch}
            onChangeHandler={onChangeHandler}
            onCaptchaClickHandler={onCaptchaClickHandler}
            redirectURL={redirectURL}
            testingEnv={testingEnv}
            ignoreCaptchaValidation={ignoreCaptchaValidation}
            {...props}
          />
        );
      default:
        return <></>;
    }
  };

  if (screen === 'signup' && !userType) {
    return <UserTypeSelector />;
  } else {
    return (
      <PayAuthLayout
        LeftGridItem={renderLeftPanel(screen)}
        RightGridItem={renderRightPanel(screen)}
      />
    );
  }
};

export default PayAuth;
