import React, { useState, useEffect } from 'react';
import MuiPhoneNumber from './MuiPhoneNumber';
import * as _ from 'lodash';
import { Button, Link } from '@material-ui/core';
import firebase from '../firebase';
import OTPInput from './OTPInput';
import { validateFields } from '../../utils/validator';
import { signInWithPhoneNumber } from '../firebase/helper';
import ErrorText from '../common/ErrorText';

const MobileInput = props => {
  const [contact, setContact] = useState('');
  const [error, setError] = useState({});
  const [codeverify, setCodeverify] = useState(null);
  const [mobileIdToken, setMobileIdToken] = useState(false);
  const [otpView, setOtpView] = useState(false);

  useEffect(() => {
    window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
      'recaptcha-container',
      {
        size: 'invisible',
        callback: function (response) {
          // reCAPTCHA solved
        },
      }
    );
    // window.recaptchaVerifier.render();
  }, []);

  const onChange = e => {
    setContact(e.target.value);
    setErrorMessage('phone', '');
  };

  const setErrorMessage = (type, value) => {
    setError({ ...error, [type]: value });
  };

  const onSubmit = async e => {
    e.preventDefault();
    processMobileLogin();
  };

  const backClick = () => {
    props.setView('root');
    setOtpView(false);
    props.setRootView(null);
  };

  const processMobileLogin = async () => {
    props.startPageLoader();
    let validatePhone = validateFields('phone', contact);

    if (validatePhone) {
      props.stopPageLoader();
      return setErrorMessage('phone', validatePhone);
    }

    signInProcess();
  };

  const signInProcess = async () => {
    try {
      let confirmationResult = await signInWithPhoneNumber(contact);
      props.setRootView('mobile');
      setOtpView(true);
      setCodeverify(confirmationResult);
      props.stopPageLoader();
    } catch (error) {
      props.stopPageLoader();
      setOtpView(false);
      props.showSnackbar(error.message);
    }
  };
  return (
    <div>
      <div id="recaptcha-container" className="d-none"></div>
      <div id="recaptcha-privacy" className="invisible">
        <small>
          This site is protected by reCAPTCHA and the Google <br />
          <a href="https://policies.google.com/privacy">Privacy Policy</a> and
          <a href="https://policies.google.com/terms">Terms of Service</a>{' '}
          apply.
        </small>
      </div>
      {props.rootView == 'mobile' && otpView ? (
        <OTPInput
          {...props}
          error={error}
          setErrorMessage={setErrorMessage}
          reSendOTP={onSubmit}
          codeverify={codeverify}
          mobile={contact}
          backClick={backClick}
        />
      ) : (
        <form onSubmit={onSubmit}>
          <div className="row">
            <div className="form-group col-12 col-sm-12 col-md-12 mt-4 mb-1">
              <label className="sk-up-ob-pf-form-lbl">
                Mobile Number <span className="sk-red-txt">*</span>
              </label>
              <MuiPhoneNumber
                className="w-100"
                name="phone"
                value={_.get(contact, ['phone'], '')}
                onChange={onChange}
                error={!_.isEmpty(_.get(error, ['phone'], ''))}
                variant="outlined"
              />
              <ErrorText
                error={error}
                name={'phone'}
                tooltip={'must include numbers only'}
              />
            </div>
          </div>

          <div className="text-center">
            <Button
              className="w-100 form-action-button"
              variant="contained"
              color="primary"
              type="submit"
            >
              Continue
            </Button>
          </div>
        </form>
      )}
    </div>
  );
};

export default MobileInput;
