import React from 'react';
import {
  Box,
  Card,
  CardContent,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  styled,
} from '@material-ui/core';
import CheckCircleOutlinedIcon from '@material-ui/icons/CheckCircleOutlined';
import Button from '../../designSystem/Button';

const StyledListItem = styled(ListItem)(({ theme }) => ({
  paddingLeft: 0,
  paddingRight: 0,
  '&>.MuiListItemIcon-root': {
    minWidth: theme.spacing(5),
  },
}));

const UserSelectionCard = ({ title, features, description, button }) => {
  return (
    <Card style={{ height: '100%' }}>
      <CardContent>
        <Box
          height="100%"
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
        >
          <div>
            {title}
            <List>
              {features.map((feature, index) => (
                <StyledListItem key={index}>
                  <ListItemIcon>
                    <CheckCircleOutlinedIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={feature}
                    primaryTypographyProps={{
                      variant: 'subtitle1',
                    }}
                  />
                </StyledListItem>
              ))}
            </List>
            <Typography variant="body1">{description}</Typography>
          </div>
          <div style={{ marginTop: '24px' }}>
            <Button color="primary" variant="contained" {...button.props}>
              {button.label}
            </Button>
          </div>
        </Box>
      </CardContent>
    </Card>
  );
};

export default UserSelectionCard;
