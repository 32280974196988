import React from 'react';
import { Box, Divider, Typography, styled } from '@material-ui/core';

const StyledDivider = styled(Divider)({
  flex: 1,
});

const DividerWithText = ({ text }) => {
  return (
    <Box display="flex" alignItems="center">
      <StyledDivider variant="middle" />
      <Typography>{text}</Typography>
      <StyledDivider variant="middle" />
    </Box>
  );
};

export default DividerWithText;
