import React, { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useLocation, useHistory } from 'react-router-dom';
import queryString from 'query-string';
import { useDispatch } from 'react-redux';
import {
  TextField,
  Box,
  Button,
  Checkbox as MuiCheckBox,
  FormControlLabel,
  makeStyles,
  withStyles,
  InputAdornment,
  IconButton,
} from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';

import {
  showSnackbar,
  startPageLoader,
  stopPageLoader,
} from '../../../redux/actions/appAction';
import {
  signOutUser,
  checkIfEmailExist,
  updateUserDetails,
  signUpWithEmailAndPassword,
} from '../../../components/firebase/helper';
import { payCreateUserMeta } from '../../../utils/generic';
import { successRedirectionCopy } from '../../../components/common/helper';
import { USER_ROLE, USER_TYPE } from '../../../utils/constants';
import { validateFields } from '../../../utils/validator';

const defaultState = {
  email: '',
  password: '',
  fullName: '',
  companyName: '',
  numerOfHire: 0,
};

const useStyles = makeStyles(theme => ({
  formCtnr: {
    width: '100%',
    background: '#fff',
    border: '1px solid #E4E7EC',
    WebkitBoxSizing: 'border-box',
    boxSizing: 'border-box',
    WebkitBoxShadow: '0px 4.37563px 102.827px rgba(0, 0, 0, 0.35)',
    boxShadow: '0px 4.37563px 102.827px rgba(0, 0, 0, 0.35)',
    borderRadius: '5px',
    padding: '42px 20px 42px 20px',
    [theme.breakpoints.up('xl')]: {
      width: '500px',
    },
    [theme.breakpoints.down('xs')]: {
      padding: '20px',
    },
  },
  formHeaderCtnr: {
    paddingBottom: '29px',
    [theme.breakpoints.down('xs')]: {
      paddingBottom: '13px',
    },
  },
  formHeader: {
    fontFamily: 'Sk-Modernist',
    fontWeight: 'bold',
    fontSize: '25.82px',
    lineHeight: '32px',
    color: '#585858',
    [theme.breakpoints.down('xs')]: {
      fontSize: '19.23px',
      lineHeight: '24px',
    },
  },
  input: {
    width: '100%',
  },
  termOfSevice: {
    fontSize: '12px',
    color: '#585858',
    fontWeight: '600',
    '& > a': {
      color: '#A158FF',
    },
  },
  submitBtn: {
    width: '100%',
    background: '#13B68F',
    borderRadius: '4.37563px',
    fontWeight: '700',
    height: '50px',
    boxShadow: 'none',
    fontFamily: 'Sk-Modernist',
    fontSize: '18px',
    lineHeight: '27px',
    '&:hover': {
      background: '#13B68F',
      boxShadow: 'none',
    },
    '&:active': {
      boxShadow: 'none',
    },
  },
}));

const CheckBox = withStyles({
  root: {
    color: '#CBCBCB',
    '&$checked': { color: '#13B68F' },
  },
  checked: {},
})(props => <MuiCheckBox color="default" {...props} />);

const SignupForm = props => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();

  const queryParm = queryString.parse(location.search);
  const [state, setStateHook] = useState(defaultState);
  const setState = n => setStateHook(o => ({ ...o, ...n }));
  const [newsLetterSubscription, setNewsLetterSubscription] = useState(false);
  const [error, setError] = useState({});
  const [showPassword, setShowPassword] = useState(false);

  const classes = useStyles();

  const data = {
    email: decodeURIComponent(queryParm.email || ''),
    name: decodeURIComponent(queryParm.name || ''),
    companyName: decodeURIComponent(queryParm.companyName || ''),
    numerOfHire: decodeURIComponent(queryParm.numerOfHire || 0),
  };

  useEffect(() => {
    const verifyUserExistsInFirebase = async email => {
      dispatch(startPageLoader());
      try {
        const isEmailExist = await checkIfEmailExist(email);
        if (isEmailExist.length) {
          enqueueSnackbar('This email is already exist please login', {
            key: 'paysignup',
            variant: 'error',
          });
          history.replace('/pay');
        } else {
          setState({ ...data });
        }
        dispatch(stopPageLoader());
      } catch (err) {
        throw err;
      }
    };
    if (!data.email || !data.name || !data.numerOfHire) {
      history.replace('/pay');
    }
    verifyUserExistsInFirebase(data.email);
    setState(data);

    return () => {
      setState(defaultState);
    };
  }, [data, dispatch, enqueueSnackbar, history]);

  const validateForm = () => {
    for (let key in error) {
      if (error[key]) {
        return false;
      }
    }
    return true;
  };

  const submitForm = async e => {
    e.preventDefault();
    if (validateForm()) {
      const { password, email, name, companyName, numerOfHire } = state;
      dispatch(startPageLoader());
      try {
        await signUpWithEmailAndPassword(email, password);
        await updateUserDetails(name);
        const meta = payCreateUserMeta(
          { role: USER_ROLE.Company, type: USER_TYPE.Employer },
          {
            companyName,
            newsLetterSubscription,
            plan: { contractorsAndEmployees: Number(numerOfHire || 0) },
          }
        );
        const response = await successRedirectionCopy(
          { ...props, showSnackbar },
          '',
          meta
        );
        if (response?.data?.success) {
          await signOutUser();
          window.location.replace(
            `${decodeURIComponent(
              queryParm.redirect_url || process.env.REACT_APP_DEFAULT_PAY_URL
            )}`
          );
        }
        dispatch(stopPageLoader());
      } catch (error) {
        dispatch(showSnackbar(error.message));
      }
      dispatch(stopPageLoader());
    }
  };

  const handleFormChange = e => {
    const { name, value } = e.target;
    setState({ [name]: value });
    setError(error => ({
      ...error,
      [name]: validateFields(name, value, {
        role: USER_ROLE.Company,
      }),
    }));
  };

  return (
    <>
      <form
        onSubmit={submitForm}
        style={{ display: 'flex', justifyContent: 'end' }}
      >
        <Box className={classes.formCtnr}>
          <Box className={classes.formHeaderCtnr}>
            <h1 className={classes.formHeader}>You’re one step away!</h1>
          </Box>

          <Box width="100%" marginBottom="17px">
            <TextField
              name="name"
              placeholder="Full Name"
              variant="outlined"
              className={classes.input}
              value={state.name || ''}
              onChange={handleFormChange}
              error={!!error.name}
              helperText={error.name}
              required
            />
          </Box>

          <Box width="100%" marginBottom="17px">
            <TextField
              name="companyName"
              placeholder="Company Name"
              variant="outlined"
              className={classes.input}
              value={state.companyName || ''}
              onChange={handleFormChange}
              error={!!error.companyName}
              helperText={error.companyName}
              required
            />
          </Box>

          <Box width="100%" marginBottom="17px">
            <TextField
              name="email"
              placeholder="Company Email"
              variant="outlined"
              className={classes.input}
              value={state.email || ''}
              onChange={handleFormChange}
              error={!!error.email}
              helperText={error.email}
              required
            />
          </Box>

          <Box width="100%" marginBottom="26px">
            <TextField
              name="password"
              placeholder="Create a password"
              variant="outlined"
              className={classes.input}
              value={state.password || ''}
              type={showPassword ? 'text' : 'password'}
              onChange={handleFormChange}
              error={!!error.password}
              helperText={error.password}
              required
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword(show => !show)}
                      edge="end"
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <Box width="100%" marginBottom="26px">
            <FormControlLabel
              className={classes.privacy}
              control={
                <CheckBox
                  checked={newsLetterSubscription}
                  onChange={e => setNewsLetterSubscription(e.target.checked)}
                />
              }
              label={
                <Box
                  display="flex"
                  alignItems="center"
                  flexDirection="column"
                  justifyContent="center"
                >
                  <p className={classes.termOfSevice} style={{ margin: 0 }}>
                    I wish to receive updates and the latest info from Skuad
                  </p>
                </Box>
              }
            />
            <p className={classes.termOfSevice}>
              By signing up you agree to our&nbsp;
              <a href="https://www.skuad.io/privacy" target="__blank">
                Terms of Service & Privacy Policy.
              </a>
            </p>
          </Box>
          <Box>
            <Button
              variant="contained"
              color="primary"
              className={classes.submitBtn}
              type="submit"
            >
              <Box display="flex" justifyContent="space-between" width="100%">
                <div>Submit</div>
                <div>
                  <svg width="29" height="25" viewBox="0 0 29 25" fill="none">
                    <path
                      d="M6.22498 12.1299H22.722"
                      stroke="white"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M14.4734 5.12988L22.7219 12.1299L14.4734 19.1299"
                      stroke="white"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
              </Box>
            </Button>
          </Box>
        </Box>
      </form>
    </>
  );
};

export default SignupForm;
