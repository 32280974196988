import HireSignInIllustration1 from '../../images/signup_graphic_1_web.svg';
import HireSignInIllustration2 from '../../images/signup_graphic_2_web.svg';
import SignInIllustration1 from '../../images/SignInIllustration1.svg';
import SignInIllustration2 from '../../images/SignInIllustration2.svg';
import Logo from '../../images/logo.svg';
import AllremoteLogo from '../../images/allremote-logo.svg';
import PayLogo from '../../images/pay-logo.svg';
import HireSignInIllustration from '../../images/signin-illustration-v2.svg';
import HireSignUpIllustration from '../../images/hire-signup.svg';
import HireOtpIllustration from '../../images/hire-otp.svg';
import PaySignInIllustration from '../../images/pay-illustration-login.svg';
import PaySignUpIllustration from '../../images/pay-signup.svg';
import PayForgotPasswordIllustration from '../../images/pay-forgot-password.svg';
import HirePasswordIllustration from '../../images/hire-password.svg';
import authbg from '../../images/auth/auth-bg.svg';

export default {
  hire: {
    name: 'hire',
    signup: true,
    modes: {
      mobile: {
        otp: true,
      },
      google: {},
    },
    required: {
      name: true,
      email: true,
      mobile: true,
    },
    views: {
      root: {
        illustration: HireSignInIllustration,
        heading: 'Welcome to Skuad',
        subHeading: 'Enter your mobile number to sign in to skuad platform',
      },
      otp: {
        illustration: HireOtpIllustration,
        heading: 'Verification Required',
      },
      'email-verify': {
        illustration: HireSignInIllustration,
        heading: 'Email Verification Successful',
        subHeading: 'click on continue to proceed further.',
      },
      data: {
        illustration: HireSignUpIllustration,
        heading: 'Complete your Skuad Profile',
        subHeading:
          'Complete your profile in few easy steps and get matched to amazing global opportunities',
      },
    },
    logo: Logo,
  },
  allremote: {
    name: 'allremote',
    signup: true,
    modes: {
      // mobile: {
      //   otp: false,
      // },
      google: {},
    },
    css: {
      backgroundImage: `url(${authbg})`,
      mobilBackgroundImage: `url(${authbg})`,
      leftPanelWidth: '470px',
      leftPanelPosition: 'inherit',
      padding: '40px',
      logoMargin: '0 0 100px 0',
      logoMarginMobile: '20px',
      mobileColor: 'white',
    },
    required: {
      name: false,
      email: false,
      mobile: false,
    },
    views: {
      root: {
        //illustration: HireSignInIllustration,
        heading: 'Welcome to AllRemote',
        subHeading: 'Continue with google to sign in to all remote platform.',
        textArray: [
          {
            heading: 'Save your professional Resumes',
          },
          {
            heading: 'Stop searching & start getting matched Jobs',
          },
          {
            heading: 'Get vetted - Get hired fast',
          },
        ],
      },
      // otp: {
      //   illustration: HireOtpIllustration,
      //   heading: 'Verification Required',
      // },
      'email-verify': {
        illustration: HireSignInIllustration,
        heading: 'Email Verification Successful',
        subHeading: 'click on continue to proceed further.',
      },
      data: {
        illustration: HireSignUpIllustration,
        heading: 'Complete your AllRemote Profile',
        subHeading:
          'Complete your profile in few easy steps and get matched to amazing global opportunities',
        title: 'Why join our community',
        textArray: [
          {
            heading: 'Save your professional Resumes',
            text: ' Create great looking and highly functional resumes. Match with job you are applying for',
          },
          {
            heading: 'Stop searching & start getting matched Jobs',
            text: 'Get AI matched jobs directly in your email.',
          },
          {
            heading: 'Get vetted - Get hired fast',
            text: 'Cut short the interview process. You need to get vetted once by our team. We put you right in front of hiring process.',
          },
        ],
      },
    },
    logo: AllremoteLogo,
    logoMobile: AllremoteLogo,
  },
  admin: {
    name: 'admin',
    signup: false,
    modes: {
      google: {},
    },
    required: {},
    views: {
      root: {
        illustration: HireSignInIllustration,
        heading: 'Welcome to Skuad',
        subHeading: 'Sign in to skuad platform',
      },
    },
    logo: Logo,
  },
  pay: {
    name: 'pay',
    signup: true,
    css: {
      backgroundColor: '#F2F6FC',
      leftPanelWidth: '420px',
      leftPanelPosition: 'inherit',
    },
    modes: {
      google: {},
      email: {},
    },
    required: {
      name: true,
      email: true,
    },
    views: {
      root: {
        // illustration:PaySignInIllustration,
        text: 'Automate your global payroll & compliance',
        heading: 'Welcome!',
        subHeading: 'Sign in to stay updated on your professional world.',
      },
      password: {
        illustration: PaySignUpIllustration,
        heading: 'Continue with Pay',
        subHeading: '',
      },
      'forgot-email': {
        text: 'Automate your global payroll & compliance',
        // illustration:PayForgotPasswordIllustration,
        heading: 'Forgot Password?',
        subHeading:
          'Enter your email below to receive your password reset instructions.',
      },
      thankyou: {
        text: 'Automate your global payroll & compliance',
        // illustration:PayForgotPasswordIllustration,
        heading: 'Thank you',
      },
      reset: {
        text: 'Automate your global payroll & compliance',
        // illustration:PayForgotPasswordIllustration,
        heading: 'Reset Password',
        subHeading: 'Please Enter the new Password.',
      },
      'email-verify': {
        text: 'Automate your global payroll & compliance',
        // illustration:PaySignInIllustration,
        heading: 'Email Verification Successful',
        subHeading: 'Click on continue to proceed further.',
      },
      data: {
        illustration: PaySignUpIllustration,
        heading: 'Continue with Pay',
        subHeading: 'Complete your profile in few easy steps.',
      },
    },
    logo: Logo,
  },
  company: {
    name: 'company',
    signup: true,
    modes: {
      google: {},
      email: {},
    },
    required: {
      name: true,
      email: true,
    },
    views: {
      root: {
        illustration: HireSignInIllustration,
        heading: 'Welcome to Skuad',
        subHeading: 'Sign to Skuad Platform.',
      },
      password: {
        illustration: HirePasswordIllustration,
        heading: 'Continue with Skuad',
        subHeading: '',
      },
      'forgot-email': {
        illustration: HirePasswordIllustration,
        heading: 'Forgot Password?',
        subHeading:
          'Enter your email below to receive your password reset instructions.',
      },
      thankyou: {
        illustration: HirePasswordIllustration,
        heading: 'Thank you',
      },
      reset: {
        illustration: HirePasswordIllustration,
        heading: 'Reset Password',
        subHeading: 'Please Enter the new Password.',
      },
      'email-verify': {
        illustration: HireSignInIllustration,
        heading: 'Email Verification Successful',
        subHeading: 'click on continue to proceed further.',
      },
      data: {
        illustration: HireSignUpIllustration,
        heading: 'Complete your Skuad Profile',
        subHeading:
          'Complete your profile in few easy steps and get matched to amazing global opportunities.',
      },
    },
    logo: Logo,
  },
};
