import React from 'react';
import { Box, Grid, makeStyles } from '@material-ui/core';
import HeroImage from './HeroImage';

const useStyles = makeStyles(theme => ({
  mainCtnr: {
    display: 'grid',
    [theme.breakpoints.down('md')]: {
      display: 'flex',
    },
  },
  msgCtnr: {
    paddingTop: '54px',
    [theme.breakpoints.down('sm')]: {
      paddingTop: '0',
    },
    [theme.breakpoints.down('xs')]: {
      padding: '0 10px',
    },
  },
  mainHeadingCtnr: {
    padding: '0 55px 0 70px',
    [theme.breakpoints.down('sm')]: {
      padding: '0',
    },
  },
  mainHeading: {
    color: '#fff',
    fontFamily: 'Sk-Modernist',
    fontWeight: 'bold',
    fontSize: '60px',
    lineHeight: '65px',
    marginBottom: '32px',
    [theme.breakpoints.down('md')]: {
      fontSize: '36px',
      lineHeight: '37px',
      marginBottom: '10px',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '36px',
      lineHeight: '37px',
      marginBottom: '10px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '31px',
      lineHeight: '65px',
      marginBottom: '21px',
    },
  },
  headContent: {
    color: '#fff',
    fontFamily: 'Sk-Modernist',
    fontWeight: 'bold',
    fontSize: '28px',
    lineHeight: '35px',
    [theme.breakpoints.down('md')]: {
      fontSize: '26px',
      lineHeight: '37px',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '26px',
      lineHeight: '37px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '24px',
      lineHeight: '35px',
    },
  },
  heroGridCtnr: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
}));

const PageContent = () => {
  const classes = useStyles();
  return (
    <Grid container style={{ height: '100%' }} className={classes.mainCtnr}>
      <Grid item sm={6} md={12} style={{ zIndex: 2, height: 'fit-content' }}>
        <div className={classes.msgCtnr}>
          <div className={classes.mainHeadingCtnr}>
            <Box width="100%">
              <h1 className={classes.mainHeading}>You’re all set.</h1>
              <p className={classes.headContent}>
                We look forward to speaking with you. Sign up now to start
                hiring and paying your global team effortlessly.
              </p>
            </Box>
          </div>
        </div>
      </Grid>
      <Grid item sm={6} md={12} className={classes.heroGridCtnr}>
        <HeroImage />
      </Grid>
    </Grid>
  );
};

export default PageContent;
