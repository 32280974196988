export const ActionTypes = {
  UpdatePassword: 'UPDATE_PASSWORD',
  ResetPasswordSuccess: 'RESET_PASSWORD_SUCCESS',
  SetPasswordError: 'SET_PASSWORD_ERROR',
  UpdateCaptchaToken: 'UPDATE_CAPTCHA_TOKEN',
};

export const ResetPassWordInitialState = {
  password: undefined,
  passwordError: {},
  isResetPasswordSuccess: false,
  captchaToken: '',
};

export const reducer = (state, action) => {
  switch (action.type) {
    case ActionTypes.UpdateUserRole:
      return {
        ...state,
        userRole: action.payload,
      };
    case ActionTypes.UpdatePassword:
      return { ...state, password: action.payload };
    case ActionTypes.ResetPasswordSuccess:
      return { ...state, isResetPasswordSuccess: action.payload };
    case ActionTypes.SetPasswordError:
      return { ...state, passwordError: action.payload };
    default:
      return state;
  }
};
