import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  mainLogo: {
    height: '55px',
    [theme.breakpoints.down('sm')]: {
      height: '55px',
    },
    [theme.breakpoints.down('xs')]: {
      height: '40px',
    },
  },
}));

const MainLogo = () => {
  const classes = useStyles();
  return (
    <img src="/newskuadicon.svg" alt="skuad" className={classes.mainLogo} />
  );
};

export default MainLogo;
