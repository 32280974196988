import React from 'react';

function PhoneIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="26"
      fill="none"
      viewBox="0 0 20 26"
    >
      <path
        stroke="#292D32"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M19 7v12c0 4.8-1.125 6-5.625 6h-6.75C2.125 25 1 23.8 1 19V7c0-4.8 1.125-6 5.625-6h6.75C17.875 1 19 2.2 19 7zM12.25 5.2h-4.5"
      ></path>
      <path
        stroke="#292D32"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M10 21.52c.963 0 1.744-.833 1.744-1.86 0-1.027-.781-1.86-1.744-1.86s-1.744.833-1.744 1.86c0 1.027.78 1.86 1.744 1.86z"
      ></path>
    </svg>
  );
}

export default PhoneIcon;
