import React from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import {
  showSnackbar,
  startPageLoader,
  stopPageLoader,
} from '../../redux/actions/appAction';
import { checkIfEmailExist } from '../firebase/helper';
import { useDispatch } from 'react-redux';
import { sendPasswordResetInviteLink } from '../../api';
import { useSnackbar } from 'notistack';
import { updateQueryParam, ObjectHasKey } from '../../utils/generic';
import { SCREEN_TYPES } from '../../utils/constants';

import ForgotPasswordForm from '../modules/ForgotPassword/ForgotPasswordForm';

const ForgotPassword = ({
  state,
  dispatch,
  onChangeHandler,
  onCaptchaClickHandler,
  ignoreCaptchaValidation,
  ...props
}) => {
  const location = useLocation();
  const history = useHistory();
  const _dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const navigateToSigninPage = () => {
    const queryParams = [
      {
        parameter: 'screen',
        value: SCREEN_TYPES.Signin,
      },
    ];
    history.replace({
      pathname: location.pathname,
      search: updateQueryParam(queryParams, location.search),
    });
  };

  const handleSubmit = async function (e) {
    e.preventDefault();

    if (!ObjectHasKey(state.errorData)) {
      try {
        _dispatch(startPageLoader());
        const email_exist = await checkIfEmailExist(
          state.formData.email.toLowerCase()
        );
        if (email_exist.length) {
          await sendPasswordResetInviteLink(props.loginMode.name, {
            email: state.formData.email.toLowerCase(),
            redirectURL: props.redirectURL,
            token: state.captchaToken,
          });
          enqueueSnackbar(
            'An email with password reset instructions has been sent to your inbox.',
            { key: 'forgotPassword', variant: 'success' }
          );
        } else {
          _dispatch(showSnackbar('Entered Email does not exist'));
        }
        navigateToSigninPage();
        _dispatch(stopPageLoader());
        return;
      } catch (error) {
        _dispatch(showSnackbar(error.message));
        _dispatch(stopPageLoader());
        return;
      }
    }
  };

  return (
    <ForgotPasswordForm
      state={state}
      onChangeHandler={onChangeHandler}
      onSubmit={handleSubmit}
      onResetPasswordButtonProps={{
        disabled:
          !state.formData.email ||
          !(ignoreCaptchaValidation || state.captchaToken) ||
          !!ObjectHasKey(state.errorData),
        id: 'form-forgotPassword-submitCTA',
      }}
      onCaptchaClickHandler={onCaptchaClickHandler}
    />
  );
};

export default ForgotPassword;
