import React, { useState } from 'react';
import { Box, Button, Chip, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PhoneVerify from './PhoneVerify';
import Authenticator from './Authenticator';
import CardWithTitle from '../designSystem/CardWithTitle';
import PhoneIcon from './icons/PhoneIcon';
import TwoFactorIcon from './icons/TwoFactorIcon';
import EmailOtp from './EmailOtp';
import { signOutUser } from '../firebase/helper';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import TwoFactorEmail from './icons/TwoFactorEmail';

const useStyles = makeStyles(theme => ({
  methodBox: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(3),
    borderRadius: '10px',
    border: '1px solid #ddd',
    cursor: 'pointer',
    marginBottom: theme.spacing(2),
    '&:hover': {
      backgroundColor: '#f5f5f5',
    },
  },
  selectedMethodBox: {
    border: '2px solid #3f51b5',
    backgroundColor: '#e8eaf6',
  },
  iconBox: {
    marginLeft: theme.spacing(2.5),
  },
  label: {
    fontWeight: 600,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    gap: 8,
  },
  description: {
    marginTop: theme.spacing(1),
  },
}));

const TwoFactorVerify = ({ setTwoFaRes, ...props }) => {
  const classes = useStyles();
  const [verifyType, setVerifyType] = useState(props.preferredAuth || '');

  const handleMethodClick = type => {
    setVerifyType(type);
  };

  const onEmail2FaVerify = async () => {
    await signOutUser();
    window.location.replace(`${decodeURIComponent(props.redirectURL)}`);
  };

  if (verifyType === 'totp') {
    return <Authenticator {...props} handleAuthChange={setVerifyType} />;
  }
  if (verifyType === 'mobile') {
    return <PhoneVerify {...props} handleAuthChange={setVerifyType} />;
  }

  if (verifyType === 'email') {
    return (
      <EmailOtp
        {...props}
        purpose="2fa_otp"
        onTryAnotherWay={() => setVerifyType('')}
        onBack={() => setTwoFaRes(null)}
        onVerify={() => onEmail2FaVerify()}
        email={props?.email || props.state.formData.email}
      />
    );
  }

  const methods = [
    {
      type: 'totp',
      icon: <TwoFactorIcon />,
      label: 'Use Authenticator App',
      description: 'Verify using a code generated via an authenticator app.',
      enabled: !!props.totp,
    },
    {
      type: 'mobile',
      icon: <PhoneIcon />,
      label: 'Use Mobile Verification',
      description: 'Verify using an OTP sent to your mobile number.',
      enabled: !!props.phone_verified,
    },
  ];

  if (!props.isGoogle) {
    methods.unshift({
      type: 'email',
      icon: <TwoFactorEmail />,
      label: 'Use Email Verification',
      description: 'Verify using a code sent to your registered email.',
      enabled: !props.isGoogle,
    });
  }

  return (
    <CardWithTitle title="Setup Two-Factor Authentication (2FA)">
      <Typography
        variant="body1"
        className={classes.label}
        style={{ marginBottom: 16 }}
      >
        Choose Verification Method
      </Typography>

      {methods
        .filter(obj => obj.type !== props.preferredAuth)
        .map(method => (
          <Box
            key={method.type}
            onClick={() => handleMethodClick(method.type)}
            className={`${classes.methodBox} ${
              verifyType === method.type ? classes.selectedMethodBox : ''
            }`}
          >
            {method.icon}
            <Box className={classes.iconBox}>
              <Typography variant="body1" className={classes.label}>
                {method.label}{' '}
                {method.enabled && (
                  <Chip label="Enabled" color="primary" size="small" />
                )}
              </Typography>
              <Typography variant="body2" className={classes.description}>
                {method.description}
              </Typography>
            </Box>
          </Box>
        ))}

      {props.preferredAuth && (
        <Button
          color="primary"
          size="small"
          onClick={() => setVerifyType(props.preferredAuth)}
        >
          <ArrowBackIcon fontSize="small" style={{ marginRight: 4 }} /> Back
        </Button>
      )}
    </CardWithTitle>
  );
};

export default TwoFactorVerify;
