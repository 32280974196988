import React, { useState, useEffect } from 'react';
import * as _ from 'lodash';
import {
  Button,
  TextField,
  FormHelperText,
  FormControl,
} from '@material-ui/core';
import ErrorText from '../common/ErrorText';

const NameInput = props => {
  return (
    <>
      <div className="row">
        <div className="form-group col-12 col-sm-6 col-md-6 mt-4 mb-1">
          <label className="sk-up-ob-pf-form-lbl">
            First Name <span className="sk-red-txt">*</span>{' '}
          </label>
          <TextField
            className="w-100"
            name="fname"
            error={!_.isEmpty(_.get(props.error, ['fname'], ''))}
            value={props.fname}
            onChange={e => {
              const nameRegex = /^[A-z \b]+$/;
              if (e.target.value === '' || nameRegex.test(e.target.value)) {
                props.setFname(e.target.value);
              }
            }}
            variant="outlined"
          />
          <ErrorText
            error={props.error}
            name={'fname'}
            tooltip={'can not be empty'}
          />
        </div>
        <div className="form-group col-12 col-sm-6 col-md-6 mt-4 mb-1">
          <label className="sk-up-ob-pf-form-lbl">
            Last Name <span className="sk-red-txt">*</span>{' '}
          </label>
          <TextField
            className="w-100"
            name="lname"
            error={!_.isEmpty(_.get(props.error, ['lname'], ''))}
            value={props.lname}
            onChange={e => {
              props.setLname(e.target.value);
            }}
            variant="outlined"
          />
        </div>
      </div>
    </>
  );
};

export default NameInput;
