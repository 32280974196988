import React, { useState, useEffect } from 'react';
import * as _ from 'lodash';
import {
  Button,
  TextField,
  FormHelperText,
  FormControl,
} from '@material-ui/core';
import TermAndPrivicyCheckbox from '../common/TermAndPrivicyCheckbox';
import {
  validateFields,
  checkResponseType,
  statusCodeValidate,
} from '../../utils/validator';
import NameInput from './NameInput';

import {
  updateUserDetails,
  getCurrentUser,
  isNewUser,
} from '../firebase/helper';
import { successRedirection } from '../common/helper';
import MuiPhoneNumber from './MuiPhoneNumber';
import ErrorText from '../common/ErrorText';

const NameEmailInput = props => {
  const [email, setEmail] = useState('');
  const [requiredFields, setRequiredFields] = useState({});
  const [terms, setTerms] = useState(false);
  const [error, setError] = useState({});
  const [contact, setContact] = useState('');
  const [fname, setFname] = useState('');
  const [lname, setLname] = useState('');
  const [name, setName] = useState();

  useEffect(() => {
    props.setView('data');
    props.startPageLoader();
    (async () => {
      let current_user = await getCurrentUser(props);
      if (current_user) {
        let required = props.loginMode.required;
        const newUser = await isNewUser();
        requiredFields['email'] = required.email ? current_user.email : true;
        requiredFields['displayName'] = required.name
          ? newUser
            ? false
            : current_user.displayName
          : true;
        if (current_user.displayName) {
          const name = current_user.displayName.split(' ');
          setFname(name.splice(0, 1));
          if (name.length) setLname(name.join(' '));
        }
        requiredFields['phoneNumber'] = required.mobile
          ? current_user.phoneNumber
          : true;

        setRequiredFields(requiredFields);
      }
      if (props.inviteUser.name) {
        const name = props.inviteUser.name.split(' ');
        setFname(name.splice(0, 1));
        if (name.length) setLname(name.join(' '));
      }
      props.stopPageLoader();
    })();
  }, [props, requiredFields]);
  const validateForm = () => {
    let error = {};
    let isValidate = true;

    if (!requiredFields.displayName) {
      let validateName = validateFields('name', fname);
      if (validateName) {
        _.set(error, 'fname', validateName);
      }
    }

    if (!requiredFields.email) {
      let validateEmail = validateFields('email', email);
      if (validateEmail) {
        _.set(error, 'email', validateEmail);
      }
    }

    if (!requiredFields.phoneNumber) {
      let validatePhone = validateFields('phone', contact);

      if (validatePhone) {
        _.set(error, 'phone', validatePhone);
      }
    }

    let validateTcAccept = validateFields('tc_accept', terms);
    if (validateTcAccept) {
      _.set(error, 'tc_accept', validateTcAccept);
    }

    if (!_.isEmpty(error)) {
      isValidate = false;
    }

    setError(error);
    return isValidate;
  };

  const handleSubmit = async function (e) {
    e.preventDefault();
    if (validateForm()) {
      props.startPageLoader();
      try {
        if (fname || email) await updateUserDetails(`${fname} ${lname}`, email);
        await successRedirection(props, contact);
      } catch (error) {
        props.showSnackbar(error.message);
        props.stopPageLoader();
        return;
      }
    }
  };

  return (
    <>
      {requiredFields && (
        <>
          <form onSubmitCapture={e => handleSubmit(e)}>
            {!requiredFields.displayName && (
              <NameInput
                fname={fname}
                lname={lname}
                setFname={setFname}
                setLname={setLname}
                error={error}
              />
            )}
            {!requiredFields.email && (
              <>
                <div className="row">
                  <div className="form-group col-12 col-sm-12 col-md-12 mt-4 mb-1">
                    <label className="sk-up-ob-pf-form-lbl">
                      Email <span className="sk-red-txt">*</span>{' '}
                    </label>
                    <TextField
                      className="w-100"
                      name="email"
                      value={email}
                      onChange={e => setEmail(e.target.value)}
                      error={!_.isEmpty(_.get(error, ['email'], ''))}
                      variant="outlined"
                    />
                    <ErrorText
                      error={error}
                      name={'email'}
                      tooltip={'Email must include @'}
                    />
                  </div>
                </div>
              </>
            )}
            {!requiredFields.phoneNumber && (
              <>
                <div className="row">
                  <div className="form-group col-12 col-sm-12 col-md-12 mt-4 mb-1">
                    <label className="sk-up-ob-pf-form-lbl">
                      Mobile Number <span className="sk-red-txt">*</span>{' '}
                    </label>
                    <MuiPhoneNumber
                      className="w-100"
                      name="phone"
                      value={_.get(contact, ['phone'], '')}
                      onChange={e => setContact(e.target.value)}
                      error={!_.isEmpty(_.get(error, ['phone'], ''))}
                      variant="outlined"
                    />
                    <ErrorText
                      error={error}
                      name={'phone'}
                      tooltip={'must include only numbers'}
                    />
                  </div>
                </div>
              </>
            )}
            <div className="sk-cm-ob-form-ctnr sk-cm-ob-form-ctnr-margin mt-4 mb-1">
              <div className="position-relative width-100">
                <FormControl className="sk-cm-ob-form-control">
                  <TermAndPrivicyCheckbox
                    color="primary"
                    checked={terms}
                    inputProps={{ 'aria-label': 'tc_accept' }}
                    onChange={e => setTerms(e.target.checked)}
                  />
                </FormControl>
              </div>
              <FormHelperText
                className="ml-3"
                error={!_.isEmpty(_.get(error, ['tc_accept'], ''))}
              >
                {_.get(error, ['tc_accept'], '')}
              </FormHelperText>
            </div>
            <div className="text-center">
              <Button
                className="w-100 form-action-button"
                variant="contained"
                color="primary"
                onClick={e => handleSubmit(e)}
              >
                Create Account
              </Button>
            </div>
          </form>
        </>
      )}
    </>
  );
};

export default NameEmailInput;
