import React, { forwardRef } from 'react';
import { FormHelperText, Typography, Box, styled } from '@material-ui/core';
import FieldGridWrapper from '../FieldGridWrapper';
import StyledFormControl from '../StyledFormControl';
import MUIPhoneNumber from 'material-ui-phone-number';

const PhoneInput = forwardRef(
  (
    {
      label,
      value,
      required,
      multiline,
      maxLength = multiline ? 500 : null,
      fullWidth,
      error,
      helperText,
      errorText,
      gridLayout,
      ...rest
    },
    ref
  ) => (
    <StyledFormControl
      fullWidth={fullWidth}
      disabled={rest.disabled}
      required={required}
      error={error}
    >
      <FieldGridWrapper label={label} gridLayout={gridLayout}>
        <MUIPhoneNumber
          {...rest}
          ref={ref}
          required={required}
          value={value}
          defaultCountry="us"
        />
        <Box display="flex" justifyContent="space-between">
          <div>
            {helperText && (
              <FormHelperText error={false}>{helperText}</FormHelperText>
            )}
            {errorText && <FormHelperText>{errorText}</FormHelperText>}
          </div>
          {multiline && (
            <FormHelperText error={false} sx={{ marginRight: 0 }}>
              <Typography
                variant="caption"
                component="span"
                color="textPrimary"
              >
                {value?.length || 0}/{maxLength}
              </Typography>
            </FormHelperText>
          )}
        </Box>
      </FieldGridWrapper>
    </StyledFormControl>
  )
);

PhoneInput.defaultProps = {
  fullWidth: true,
  gridLayout: true,
  maxRows: 3,
};

export default PhoneInput;
