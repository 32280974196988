import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import firebase from '../../components/firebase';
import { useDispatch } from 'react-redux';
import OtpForm from './OtpForm';
import EnterNumberForm from './EnterNumberForm';
import EmailOtpBackDrop from './EmailOtpBackDrop';

const useStyles = makeStyles(theme => ({
  enableText: {
    fontWeight: 600,
    marginBottom: theme.spacing(1),
  },
  instructionText: {
    marginBottom: theme.spacing(4),
  },
  recaptchaContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  buttonContainer: {
    marginTop: theme.spacing(6),
    gap: theme.spacing(3),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'baseline',
  },
  backButton: {
    marginRight: theme.spacing(2),
  },
  resendContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    marginBottom: theme.spacing(6),
    cursor: 'pointer',
  },
}));

const PhoneVerify = ({
  number: orgNumber,
  state,
  dispatch,
  handleAuthChange,
  ...props
}) => {
  console.log(props);
  const classes = useStyles();
  const _dispatch = useDispatch();

  const [number, setNumber] = useState(orgNumber);
  const [showOtp, setShowOtp] = useState(false);
  const [emailOtpVerified, setEmailOtpVerified] = useState(false);

  const captchaRef = useRef();

  const [otp, setOtp] = useState('');

  if (!props.phone_verified && !emailOtpVerified) {
    return (
      <EmailOtpBackDrop
        onVerify={() => setEmailOtpVerified(true)}
        purpose={'phone_setup'}
        email={props.email || state.formData.email}
        onBack={() => {
          handleAuthChange("")
        }}
      />
    );
  }

  return showOtp ? (
    <OtpForm
      classes={classes}
      number={number}
      orgNumber={orgNumber}
      otp={otp}
      setOtp={setOtp}
      dispatch={dispatch}
      state={state}
      _dispatch={_dispatch}
      captchaRef={captchaRef}
      setShowOtp={setShowOtp}
      handleAuthChange={handleAuthChange}
      {...props}
    />
  ) : (
    <EnterNumberForm
      classes={classes}
      number={number}
      setNumber={setNumber}
      captchaRef={captchaRef}
      setShowOtp={setShowOtp}
      orgNumber={orgNumber}
      _dispatch={_dispatch}
      props={props}
      handleAuthChange={handleAuthChange}
      onBack={() => {
        setShowOtp(false);
      }}
    />
  );
};

PhoneVerify.propTypes = {
  number: PropTypes.string.isRequired,
  state: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  props: PropTypes.object.isRequired,
};

export default PhoneVerify;
