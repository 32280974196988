import { Grid, Typography, Link as MuiLink } from '@material-ui/core';
import { updateQueryParam } from '../../utils/generic';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom';
import React from 'react';

const HaveAlreadyAccount = () => {
  const location = useLocation();
  const history = useHistory();

  const navigateToSigninPage = e => {
    e.preventDefault();

    const queryParams = [
      {
        parameter: 'screen',
        value: 'signin',
      },
    ];

    history.push({
      pathname: location.pathname,
      search: updateQueryParam(queryParams, location.search),
    });
  };

  return (
    <Grid item xs={12}>
      <Typography variant="body2" align="center">
        Already have an account?{' '}
        <MuiLink
          href="#"
          onClick={navigateToSigninPage}
          id="form-signIn-signUpLink"
        >
          Sign in
        </MuiLink>
      </Typography>
    </Grid>
  );
};

export default HaveAlreadyAccount;
