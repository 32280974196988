import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Typography, Link as MuiLink } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
  showSnackbar,
  startPageLoader,
  stopPageLoader,
} from '../../redux/actions/appAction';
import { useDispatch } from 'react-redux';
import { sendEmailForOtp, verifyEmailForOtp } from '../../api';
import CardWithTitle from '../designSystem/CardWithTitle';
import StandardTextField from '../designSystem/StandardTextField';
import { purposeMap } from './EmailOtpBackDrop';
import ResendCode from './ResendCode';

const useStyles = makeStyles(theme => ({
  enableText: {
    fontWeight: 600,
    marginBottom: theme.spacing(1),
  },
  instructionText: {
    marginBottom: theme.spacing(4),
  },
  buttonContainer: {
    marginTop: theme.spacing(6),
    gap: theme.spacing(3),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'baseline',
  },
  backButton: {
    marginRight: theme.spacing(2),
  },
}));

export function maskEmail(email, maskChar = '*') {
  // Split the email into the username and domain parts
  const [username, domain] = email.split('@');

  // Determine the part of the username to mask
  const maskedUsername =
    username.length <= 6
      ? maskChar.repeat(username.length)
      : username.slice(0, 2) + '*****' + username.slice(-1);
  // Construct the masked email
  const maskedEmail = `${maskedUsername}@${domain}`;

  return maskedEmail;
}

const EmailOtp = ({
  totp,
  onVerify,
  purpose,
  onBack,
  email,
  onTryAnotherWay,
}) => {
  const classes = useStyles();
  const _dispatch = useDispatch();
  const [token, setToken] = useState('');
  const [result, setResult] = useState({});
  const [lastSent, setLastSent] = useState(null);

  const sendEmailOtp = useCallback(async () => {
    _dispatch(startPageLoader());
    try {
      const response = await sendEmailForOtp(purpose);
      setResult(response.data);
      setLastSent(new Date().toISOString());
    } catch (error) {
      _dispatch(showSnackbar(error?.response?.data?.message || error.message));
    } finally {
      _dispatch(stopPageLoader());
    }
  });

  const verifyOtp = async () => {
    try {
      _dispatch(startPageLoader());
      const res = await verifyEmailForOtp(token, result.token);
      if (res) {
        onVerify();
      }
    } catch (error) {
      _dispatch(showSnackbar(error?.response?.data?.message || error.message));
    } finally {
      _dispatch(stopPageLoader());
    }
  };

  const handleOtpChange = e => {
    if (e.target.value.length > 6) {
      return;
    }
    setToken(e.target.value);
  };

  useEffect(() => {
    sendEmailOtp();
  }, []);

  return (
    <form
      onSubmit={e => {
        e.preventDefault();
        verifyOtp();
      }}
    >
      <CardWithTitle title="Enter the 6-digit code from your email">
        <Typography variant="body2" className={classes.instructionText}>
          Enter the 6-digit secure code sent to ({maskEmail(email)}) to{' '}
          {purposeMap[purpose]}
        </Typography>
        <StandardTextField
          gridLayout={false}
          placeholder="6 digit secure code"
          helperText={
            <Box
              display="flex"
              sx={{ width: '100%' }}
              justifyContent="space-between"
              alignItems="baseline"
            >
              <Typography variant="caption"></Typography>
              <ResendCode onClick={() => sendEmailOtp()} lastSent={lastSent} />
            </Box>
          }
          value={token}
          onChange={handleOtpChange}
        />

        <Box className={classes.buttonContainer}>
          <Button
            color="primary"
            onClick={() => {
              onTryAnotherWay();
            }}
          >
            Try Another Way
          </Button>
          <Box display="flex" justifyContent="flex-end">
            <Button
              variant="outlined"
              color="primary"
              className={classes.backButton}
              onClick={() => {
                onBack();
              }}
            >
              Back
            </Button>

            <Button
              variant="contained"
              type="submit"
              color="primary"
              disabled={token.length !== 6}
            >
              Verify
            </Button>
          </Box>
        </Box>
      </CardWithTitle>
    </form>
  );

  return null;
};

EmailOtp.propTypes = {
  state: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  handleAuthChange: PropTypes.func.isRequired,
  totp: PropTypes.bool.isRequired,
  redirectURL: PropTypes.string.isRequired,
  phone_verified: PropTypes.bool.isRequired,
};

export default EmailOtp;
