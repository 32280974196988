import React, { useState, useEffect } from 'react';
import OtpInput from 'react-otp-input';
import _ from 'lodash';
import { Button, FormHelperText, Link } from '@material-ui/core';
import { validateFields } from '../../utils/validator';

import { verifyOTP } from '../firebase/helper';
import api from '../../api';
import { successRedirection } from '../common/helper';
import BackButton from '../common/back';

const OTPInput = props => {
  const [optValue, setOtpValue] = useState('');
  useEffect(() => {
    props.setSubHeading(
      `Enter OTP that has been sent to your mobile number ${props.mobile.replace(
        props.mobile.substr(5, 4),
        'xxxx'
      )}`
    );
    props.setView('otp');
  }, [props]);
  const onSubmit = async () => {
    props.startPageLoader();
    let error = validateFields('otp', optValue.toString());
    if (error) {
      props.setErrorMessage('otp', error);
      props.stopPageLoader();
      return;
    }
    try {
      await verifyOTP(props.codeverify, optValue.toString());

      await successRedirection(props);

      // props.stopPageLoader();
      // // props.setMobileIdToken(idToken);
      // props.setRootView(false)
      // props.setOTPView(false);
    } catch (error) {
      props.showSnackbar(error.message);
      props.stopPageLoader();
      return;
    }
  };

  return (
    <>
      <BackButton {...props} />
      {/* <div className="center-all sk-otp-heading mt-4">
        <b>Enter OTP</b>
      </div>
      <div className="center-all sk-otp-text mt-4">
        Enter the code sent on&nbsp;<b>{props.mobile.replace(props.mobile.substr(5,4),'xxxx')}</b>
      </div> */}
      <OtpInput
        value={optValue}
        onChange={otp => setOtpValue(otp)}
        numInputs={6}
        containerStyle="center-all mt-5"
        inputStyle="sk-otp-input"
        focusStyle={{ outline: 'none' }}
        shouldAutoFocus={true}
        // isInputNum={true}
      />
      <FormHelperText
        className="center-all"
        error={!_.isEmpty(_.get(props.error, ['otp'], ''))}
      >
        {_.get(props.error, ['otp'], '')}
      </FormHelperText>
      <div className="text-center">
        <Button
          className="w-100 form-action-button"
          variant="contained"
          color="primary"
          onClick={onSubmit}
        >
          Verify
        </Button>
      </div>
      <div className="center-all sk-tc-accpt-ctnr">
        <FormHelperText className="sk-tc-accpt-txt">
          Didn't receive the code?{' '}
          <Link href="#" onClick={props.reSendOTP}>
            Resend OTP
          </Link>
        </FormHelperText>
      </div>
    </>
  );
};

export default OTPInput;
