import {
  START_LOADER,
  STOP_LOADER,
  START_PAGE_LOADER,
  STOP_PAGE_LOADER,
  SHOW_SNACKBAR,
  HIDE_SNACKBAR,
} from './actionTypes';

export const startLoader = () => dispatch => {
  return dispatch({ type: START_LOADER });
};

export const stopLoader = () => dispatch => {
  return dispatch({ type: STOP_LOADER });
};

export const startPageLoader = () => dispatch => {
  return dispatch({ type: START_PAGE_LOADER });
};

export const stopPageLoader = () => dispatch => {
  return dispatch({ type: STOP_PAGE_LOADER });
};

export const showSnackbar = msg => dispatch => {
  console.log(msg)
  return dispatch({ type: SHOW_SNACKBAR, message: msg });
};

export const hideSnackbar = () => dispatch => {
  return dispatch({ type: HIDE_SNACKBAR });
};
