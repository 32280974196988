import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { signOutUser } from '../firebase/helper';
import { Box, Button, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ActionTypes } from '../modules/PayAuth/reducer';
import {
  showSnackbar,
  startPageLoader,
  stopPageLoader,
} from '../../redux/actions/appAction';
import { useDispatch } from 'react-redux';
import { generateQrCode, verifyOtpForUser } from '../../api';
import CardWithTitle from '../designSystem/CardWithTitle';
import AuthenticatorSetup from './AuthenticatorSetup';
import StandardTextField from '../designSystem/StandardTextField';
import EmailOtpBackDrop from './EmailOtpBackDrop';

const useStyles = makeStyles(theme => ({
  enableText: {
    fontWeight: 600,
    marginBottom: theme.spacing(1),
  },
  instructionText: {
    marginBottom: theme.spacing(4),
  },
  buttonContainer: {
    marginTop: theme.spacing(6),
    gap: theme.spacing(3),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'baseline',
  },
  backButton: {
    marginRight: theme.spacing(2),
  },
}));

const Authenticator = ({
  state,
  dispatch,
  handleAuthChange,
  totp,
  redirectURL,
  phone_verified,
  ...props
}) => {
  const classes = useStyles();
  const _dispatch = useDispatch();
  const [two2FASetupObject, setTwo2FASetupObject] = useState(null);
  const [token, setToken] = useState('');
  const [showInputScreen, setShowInputScreen] = useState(totp);

  const [emailOtpVerified, setEmailOtpVerified] = useState(false);

  const handleGenerateQrCode = async () => {
    _dispatch(startPageLoader());
    try {
      const response = await generateQrCode();
      setTwo2FASetupObject(response.data);
    } catch (error) {
      _dispatch(showSnackbar(error?.response?.data?.message || error.message));
    } finally {
      _dispatch(stopPageLoader());
    }
  };

  const verifyOtp = async () => {
    try {
      _dispatch(startPageLoader());
      const res = await verifyOtpForUser(token);
      dispatch({
        type: ActionTypes.UpdateMetaObject,
        payload: res?.data?.meta,
      });

      if (res?.data?.success) {
        await signOutUser();
        window.location.replace(`${decodeURIComponent(redirectURL)}`);
      }
    } catch (error) {
      _dispatch(showSnackbar(error?.response?.data?.message || error.message));
    } finally {
      _dispatch(stopPageLoader());
    }
  };

  const handleOtpChange = e => {
    if (e.target.value.length > 6) {
      return;
    }
    setToken(e.target.value);
  };

  useEffect(() => {
    if (!totp) {
      handleGenerateQrCode();
    }
  }, [totp]);

  useEffect(() => {
    if (phone_verified && !totp) {
      //handle email otp here
      // sendEmailVerification().then(data => {});
    }
  }, [totp, phone_verified]);

  if (!totp && !emailOtpVerified) {
    return (
      <EmailOtpBackDrop
        onVerify={() => setEmailOtpVerified(true)}
        purpose={'totp_setup'}
        email={props.email || state.formData.email}
        onBack={() => {
          handleAuthChange('');
        }}
      />
    );
  }

  if (showInputScreen) {
    return (
      <form
        onSubmit={e => {
          e.preventDefault();
          verifyOtp();
        }}
      >
        <CardWithTitle
          title={
            totp
              ? 'Enter the 6-digit code from your authenticator app'
              : 'Setup Two-Factor Authentication (2FA)'
          }
        >
          {!totp && (
            <Typography variant="body1" className={classes.enableText}>
              Enable authenticator app
            </Typography>
          )}
          <Typography variant="body2" className={classes.instructionText}>
            {totp
              ? "This helps us keep your account secure by verifying that it's really you."
              : 'To make sure everything works, enter the 6 digit secure code from your authenticator app.'}
          </Typography>
          <StandardTextField
            gridLayout={false}
            placeholder="6 digit secure code"
            value={token}
            onChange={handleOtpChange}
          />

          <Box className={classes.buttonContainer}>
            <Button
              variant="text"
              color="primary"
              onClick={() => {
                handleAuthChange(!props.isGoogle ? '' : 'mobile');
              }}
            >
              {!props.isGoogle
                ? 'Try Another Way'
                : phone_verified
                ? 'Use Mobile'
                : 'Setup Mobile Authentication'}
            </Button>
            <Box display="flex" justifyContent="flex-end">
              {!totp && (
                <Button
                  variant="outlined"
                  color="primary"
                  className={classes.backButton}
                  onClick={() => {
                    setShowInputScreen(false);
                  }}
                >
                  Back
                </Button>
              )}

              <Button
                variant="contained"
                type="submit"
                color="primary"
                disabled={token.length !== 6}
              >
                {totp ? 'Verify' : 'Enable'}
              </Button>
            </Box>
          </Box>
        </CardWithTitle>
      </form>
    );
  }

  if (two2FASetupObject) {
    return (
      <AuthenticatorSetup
        {...props}
        two2FASetupObject={two2FASetupObject}
        state={state}
        dispatch={dispatch}
        handleAuthChange={handleAuthChange}
        onSubmit={e => {
          e.preventDefault();
          setShowInputScreen(true);
        }}
        onBack={() => handleAuthChange('')}
      />
    );
  }

  return null;
};

Authenticator.propTypes = {
  state: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  handleAuthChange: PropTypes.func.isRequired,
  totp: PropTypes.bool.isRequired,
  redirectURL: PropTypes.string.isRequired,
  phone_verified: PropTypes.bool.isRequired,
};

export default Authenticator;
