import React from 'react';
import { Provider } from 'react-redux';
import store from './redux/store';
import { BrowserRouter } from 'react-router-dom';
import Router from './router';
import ApiLoader from './components/loader/ApiLoader';
import PageLoader from './components/loader/PageLoader';
import SnackbarLoader from './components/loader/SnackbarLoader';
import Theme from './theme';
import { SnackbarProvider } from 'notistack';
import ErrorBoundary from './utils/ErrorBoundary';
import './scss/index.scss';
import Cookies from 'js-cookie';
import jwt from 'jsonwebtoken';
const JWT_PUBLIC_KEY = process.env.REACT_APP_JWT_PUBLIC_KEY;

class App extends React.Component {
  state = {
    inviteIdentifier: false,
    inviteExternalId: false,
    inviteUser: {},
  };
  componentDidMount() {
    (async () => {
      let token = Cookies.get('SK_ID_INV2');
      try {
        let decoded = await jwt.verify(
          token,
          JWT_PUBLIC_KEY.replace(/\\n/g, '\n')
        );
        if (decoded) {
          if (decoded.identifier == 'email' && decoded.externalId) {
            decoded.externalId = decoded.externalId.toLowerCase();
          }

          this.setState({
            inviteIdentifier: decoded.identifier,
            inviteExternalId: decoded.externalId,
            inviteUser: decoded.user,
          });
        }
      } catch (err) {}
    })();
  }
  render() {
    return (
      <ErrorBoundary>
        <Provider store={store}>
          <BrowserRouter>
            <Theme>
              <SnackbarProvider
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                maxSnack={1}
              >
                <ApiLoader />
                <PageLoader />
                <SnackbarLoader />
                <Router dispatch={store.dispatch} {...this.state} />
              </SnackbarProvider>
            </Theme>
          </BrowserRouter>
        </Provider>
      </ErrorBoundary>
    );
  }
}

export default App;
