import React, { useEffect } from 'react';
import clsx from 'clsx';
import { Box, Grid, makeStyles } from '@material-ui/core';
import MainLogo from './components/MainLogo';
import SignupForm from './components/SignupForm';
import PageContent from './components/PageContent';
import HeroImage from './components/HeroImage';
import './scss/style.scss';
import useTitle from '../../hooks/useTitle';

const useStyles = makeStyles(theme => ({
  mainCtrn: {
    backgroundColor: '#5c4eb4',
    height: '100vh',
    padding: '40px 56px 0 56px',
    overflow: 'auto',
    [theme.breakpoints.up('xl')]: {
      paddingRight: '80px',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '32px 36px',
    },
  },
  headerCtnr: {
    marginBottom: '77px',
    height: '55px',
    [theme.breakpoints.down('sm')]: {
      marginBottom: '35px',
      height: '42px',
    },
  },
  mainGridCrtnr: {
    height: 'calc(100vh - 55px - 70px - 40px)',
    [theme.breakpoints.down('md')]: {
      height: 'calc(100vh - 55px - 77px - 40px)',
    },
    [theme.breakpoints.down('sm')]: {
      height: 'auto',
    },
  },
  signupFormWrapper: {
    [theme.breakpoints.down('sm')]: {
      padding: '0 10%',
    },
    [theme.breakpoints.down('xs')]: {
      padding: '0',
    },
  },
  heroGridCtnr: {
    display: 'none',
    [theme.breakpoints.only('xs')]: {
      display: 'block',
    },
  },
}));

const PayWebSignup = props => {
  const classes = useStyles();
  useTitle('Employer Signup');

  return (
    <div
      className={clsx('pay-web-signup-root', classes.mainCtrn)}
      id="pay-web-signup-root"
    >
      <Box width="100%" className={classes.headerCtnr}>
        <MainLogo />
      </Box>
      <Grid container spacing={2} className={classes.mainGridCrtnr}>
        <Grid item xs={12} sm={12} md={6} lg={7} style={{ height: '100%' }}>
          <PageContent />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={5}>
          <div className={classes.signupFormWrapper}>
            <SignupForm {...props} />
          </div>
        </Grid>
        <Grid item xs={12} className={classes.heroGridCtnr}>
          <HeroImage />
        </Grid>
      </Grid>
    </div>
  );
};

export default PayWebSignup;
