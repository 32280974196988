import React from 'react';
import errorImage from '../../images/error-image.png';

export default function Img(props) {
  var img_props = {
    alt: '',
    onError: e => {
      e.target.onerror = null;
      e.target.src = errorImage;
    },
  };
  img_props = { ...img_props, ...props };
  return <img {...img_props} alt={img_props.alt} />;
}
