import React from 'react';
import clsx from 'clsx';
import { Box, Hidden, makeStyles } from '@material-ui/core';
import { ReactComponent as SkuadLogo } from '../../images/auth/skuad-logo.svg';
import authbg from '../../images/pay-auth-bg-img.png';

const useStyles = makeStyles(theme => ({
  AuthLayoutWrapper: {
    display: 'flex',
    height: '100vh',
    color: '#fff',
    fontFamily: 'Open Sans',
  },
  AuthLeftPanelWrapper: {
    padding: '40px 50px',
    width: '570px',
    backgroundImage: `url(${authbg})`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    height: '100%',
    overflow: 'auto',
  },
  AuthRightPanelWrapper: {
    display: 'flex',
    minHeight: 0,
    height: '100vh',
    flexGrow: 1,
    backgroundColor: '#f8f9fe',
    overflow: 'auto',
    position: 'relative',
    [theme.breakpoints.up('sm')]: {
      padding: '40px 50px 0px 50px',
      justifyContent: 'center',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '25px',
      flexDirection: 'column',
    },
  },
  extension: {
    color: '#000',
    [theme.breakpoints.up('sm')]: {
      position: 'absolute',
      top: '40px',
      left: '35px',
    },
  },
}));

const AuthLayout = ({ left, right, extension, className }) => {
  const authStyles = useStyles();
  return (
    <Box className={authStyles.AuthLayoutWrapper}>
      <Hidden mdDown>
        <Box className={clsx(authStyles.AuthLeftPanelWrapper, 'hide-scroll')}>
          <Box mb="110px">
            <SkuadLogo />
          </Box>
          <Box display="flex" flexDirection="column" justifyContent="center">
            {left}
          </Box>
        </Box>
      </Hidden>
      <Box
        className={clsx(authStyles.AuthRightPanelWrapper, 'hide-scroll')}
        id="auth-right-panel"
      >
        {extension && <Box className={authStyles.extension}>{extension}</Box>}
        <Box
          maxWidth="520px"
          className={className}
          id="auth-right-panel-content-container"
        >
          {right}
        </Box>
      </Box>
    </Box>
  );
};

export default AuthLayout;
