import React, { useState, useEffect } from 'react';
import * as _ from 'lodash';
import {
  Button,
  TextField,
  FormHelperText,
  FormControl,
} from '@material-ui/core';
import TermAndPrivicyCheckbox from '../common/TermAndPrivicyCheckbox';
import {
  validateFields,
  checkResponseType,
  statusCodeValidate,
} from '../../utils/validator';
import { checkIfEmailExist } from '../firebase/helper';
import PasswordInput from './PasswordInput';
import ForgotPasswordEmailInput from './ForgotPasswordEmailInput';
import { FIREBASE_ERROR_MESSAGES } from '../../utils/constants';
import ErrorText from '../common/ErrorText';

const EmailInput = props => {
  const [email, setEmail] = useState('');
  const [signup, setSignup] = useState(false);
  const [passwordView, setPasswordView] = useState(false);
  const [forgotPasswordView, setForgotPasswordView] = useState(false);
  const [error, setError] = useState({});
  // useEffect(() => {
  //     if(props.inviteIdentifier && props.inviteIdentifier == 'email' && props.inviteExternalId)
  //         setEmail(props.inviteExternalId)
  // }, [])
  const validateForm = () => {
    let error = {};
    let isValidate = true;

    let validateEmail = validateFields('email', email);
    if (validateEmail) {
      _.set(error, 'email', validateEmail);
    }

    if (!_.isEmpty(error)) {
      isValidate = false;
    }

    setError(error);
    return isValidate;
  };

  const backClick = () => {
    props.setView('root');
    setPasswordView(false);
    setForgotPasswordView(false);
    props.setRootView(null);
    setSignup(false);
  };
  const onForgotPasswordClick = () => {
    props.setRootView('email');
    setForgotPasswordView(true);
  };
  const handleSubmit = async function (e) {
    e.preventDefault();

    if (validateForm()) {
      try {
        props.startPageLoader();
        let email_exist = await checkIfEmailExist(email);
        if (
          email_exist.includes('password') ||
          email_exist.includes('emailLink')
        ) {
          setPasswordView(true);
          props.setRootView('email');
        } else if (!email_exist.length && props.loginMode.signup) {
          setPasswordView(true);
          props.setRootView('email');
          setSignup(true);
        } else if (!email_exist.length && !props.loginMode.signup) {
          props.showSnackbar(FIREBASE_ERROR_MESSAGES['auth/user-not-found']);
        } else {
          props.showSnackbar(
            FIREBASE_ERROR_MESSAGES['auth/email-already-in-use']
          );
        }
        props.stopPageLoader();
        return;
      } catch (error) {
        props.showSnackbar(error.message);
        props.stopPageLoader();
        return;
      }
    }
  };

  return (
    <>
      {passwordView && props.rootView == 'email' && (
        <PasswordInput
          {...props}
          email={email}
          signup={signup}
          backClick={backClick}
        />
      )}

      {forgotPasswordView && props.rootView == 'email' && (
        <ForgotPasswordEmailInput
          {...props}
          email={email}
          signup={signup}
          backClick={backClick}
        />
      )}

      {!passwordView && !forgotPasswordView && (
        <>
          <form onSubmitCapture={e => handleSubmit(e)}>
            <div className="row">
              <div className="form-group col-12 col-sm-12 col-md-12 mt-4 mb-1">
                <label className="sk-up-ob-pf-form-lbl">
                  Email <span className="sk-red-txt">*</span>{' '}
                  <span
                    className="sk-red-txt float-right cursor-p"
                    onClick={onForgotPasswordClick}
                  >
                    Forgot Password?
                  </span>
                </label>
                <TextField
                  className="w-100"
                  name="email"
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                  error={!_.isEmpty(_.get(error, ['email'], ''))}
                  variant="outlined"
                />
                <ErrorText
                  error={error}
                  name={'email'}
                  tooltip={'Email must include @'}
                />
              </div>
            </div>

            <div className="text-center">
              <Button
                className="w-100 form-action-button"
                variant="contained"
                color="primary"
                onClick={e => handleSubmit(e)}
              >
                Continue
              </Button>
            </div>
          </form>
        </>
      )}
    </>
  );
};

export default EmailInput;
