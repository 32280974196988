import React from 'react';

function TwoFactorIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="24"
      fill="none"
      viewBox="0 0 21 24"
    >
      <path
        stroke="#292D32"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="1.5"
        d="M17.316 14.93c-1.802 2.05-4.384 2.68-6.65 1.87l-4.121 4.7c-.298.35-.884.56-1.304.49l-1.907-.3c-.63-.1-1.217-.78-1.313-1.5l-.262-2.18c-.062-.48.14-1.15.428-1.49l4.113-4.7c-.7-2.6-.158-5.55 1.645-7.6 2.581-2.95 6.772-2.95 9.362 0s2.59 7.76.01 10.71zM6.029 17.49l2.012 2.3"
      ></path>
      <path
        stroke="#292D32"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M12.688 11C13.412 11 14 10.328 14 9.5S13.412 8 12.687 8c-.724 0-1.312.672-1.312 1.5s.588 1.5 1.313 1.5z"
      ></path>
    </svg>
  );
}

export default TwoFactorIcon;
