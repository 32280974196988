import React from 'react';
import { Box, Grid, Container, styled } from '@material-ui/core';
import Header from '../common/Header';
import PayAuthBgImg from '../../images/pay-auth-bg-img.png';

const BoxWithBackground = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  height: '100%',
  backgroundImage: `url(${PayAuthBgImg})`,
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'right top',
  paddingRight: theme.spacing(10),
  [theme.breakpoints.down('sm')]: {
    backgroundImage: 'none',
    paddingRight: 0,
    display: 'none',
  },
  backgroundSize: "contain"
}));

const StyledGrid = styled(Grid)(({ theme }) => {
  return {
    [theme.breakpoints.down('sm')]: {
      gap: theme.spacing(2),
    },
  };
});

const StyledLeftGridItem = styled(Grid)(({ theme }) => {
  return {
    maxHeight: '100vh',
    position: '-webkit-sticky',
    // This is expected, as we can have same CSS properties with multiple values.
    // eslint-disable-next-line no-dupe-keys
    position: 'sticky',
    top: 0,
    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(11.5),
    },
  };
});

const StyledRightGridItem = styled(Grid)(({ theme }) => {
  return {
    paddingBottom: theme.spacing(5),
    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(6),
    },
  };
});

const PayAuthLayout = ({ LeftGridItem, RightGridItem }) => {
  return (
    <Box bgcolor="#FEFAFA" minHeight="100vh" position="relative">
      <Header formLayout />
      <Container maxWidth="lg">
        <StyledGrid container>
          <StyledLeftGridItem item xs={12} sm={12} md={6}>
            <BoxWithBackground>{LeftGridItem}</BoxWithBackground>
          </StyledLeftGridItem>
          <StyledRightGridItem item xs={12} sm={12} md={6}>
            {RightGridItem}
          </StyledRightGridItem>
        </StyledGrid>
      </Container>
    </Box>
  );
};

export default PayAuthLayout;
