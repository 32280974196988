import React from 'react';
import {
  Grid,
  Card,
  CardContent,
  Typography,
  Divider,
  Box,
} from '@material-ui/core';

const CardWithTitle = ({ title, children }) => {
  return (
    <Box pt={3} id="form-signIn">
      <Card>
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="h4" fontWeight={600}>
                {title}
              </Typography>
              <Divider style={{ marginBottom: 24 }} />
            </Grid>
          </Grid>
          {children}
        </CardContent>
      </Card>
    </Box>
  );
};

export default CardWithTitle;
