import React from 'react';
import ilus from '../../images/WebsiteCrash.svg';
import Hidden from '@material-ui/core/Hidden';
import { Box } from '@material-ui/core';

export default function InviteExpired(props) {
  return (
    <React.Fragment>
      <Box
        className="sk-404-main-ctnr hide-scroll"
        height="100%"
        overflow="auto"
        display="flex"
        flexDirection="column"
        alignItems="center"
        pb="25px"
      >
        <div className="sk-404-ttl">
          <Hidden smDown>{props.errMessage}</Hidden>
          <Hidden mdUp>
            <div>{props.errMessage}</div>
          </Hidden>
        </div>

        <div className="sk-404-ilus-ctnr">
          <img src={ilus} className="sk-404-ilus" alt="404" />
        </div>
      </Box>
    </React.Fragment>
  );
}
