/* eslint-disable no-unused-vars */
import * as React from 'react';

import {
  ThemeProvider,
  responsiveFontSizes,
  createTheme,
} from '@material-ui/core/styles';

import palette from './palette';
import typography from './typography';
import overrides from './overrides';
import shadows from './shadows';

const customMuiTheme = createTheme({
  palette: palette,
  typography: typography,
  overrides: overrides,
  shadows: shadows,
});

export const responsiveCustomMuiTheme = responsiveFontSizes(customMuiTheme);

const SkuadTheme = ({ children }) => (
  <ThemeProvider theme={responsiveCustomMuiTheme}>{children}</ThemeProvider>
);
export default SkuadTheme;
