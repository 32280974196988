import React from 'react';
import { Box } from '@material-ui/core';
import ReCAPTCHA from 'react-google-recaptcha';

const Captcha = props => (
  <Box marginY={1} display="flex" justifyContent="center" alignItems="center">
    <ReCAPTCHA
      sitekey={process.env.REACT_APP_GOOGLE_CAPTCHA_KEY}
      onChange={val => {
        props.setCaptchaToken(val);
      }}
    />
  </Box>
);

export default Captcha;
