import React from 'react';
import {
  Route,
  Switch,
  Redirect,
  BrowserRouter,
  withRouter,
} from 'react-router-dom';
import Layout from './components/layout/Layout';
import PageNotFound from './pages/404';
import InternalError from './pages/500';
import UnderMaintenance from './pages/UnderMaintenance';
import Thankyou from './pages/EmailStatus';
import Login from './pages/Login';
import PayAuth from './pages/PayAuth';
import PayWebSignup from './pages/PayWebSignup/PayWebSignup';
import Invite from './pages/Invite';
import ResetPassword from './pages/ResetPassword';
import EmailVerify from './pages/EmailVerify';
import LOGIN_MODES from './components/common/config';

import Signup from './pages/Signup';
import Signin from './pages/Signin';
import ForgotPassword from './pages/ForgotPassword';

import {
  PAY_SIGNIN,
  PAY_SIGNUP,
  PAY_FORGOT_PASSWORD,
} from './utils/routePaths';

function Router(props) {
  const renderWithLayout = (component, mode) => {
    return (
      <Layout {...props} loginMode={mode}>
        {component}
      </Layout>
    );
  };
  return (
    <BrowserRouter>
      <Switch>
        {/* ----Route Code---- */}
        <Route
          exact
          path="/hire"
          component={() =>
            renderWithLayout(
              <Login
                {...props}
                loginMode={LOGIN_MODES['hire']}
                title={'Hire Login'}
              />,
              'hire'
            )
          }
        />
        <Route
          exact
          path="/allremote"
          component={() =>
            renderWithLayout(
              <Login
                {...props}
                loginMode={LOGIN_MODES['allremote']}
                title={'All remote Login'}
              />,
              'allremote'
            )
          }
        />

        <Route
          exact
          path="/verify_email"
          component={() => renderWithLayout(<Thankyou />)}
        />

        <Route
          exact
          path="/invite/:id"
          component={() => <Invite {...props} />}
        />

        <Route
          exact
          path="/:mode/reset-password"
          component={() => (
            <ResetPassword
              {...props}
              loginMode={LOGIN_MODES[props.match.params.mode]}
              title={'Reset Password'}
            />
          )}
        />

        <Route
          exact
          path="/:mode/verify-email"
          component={() => (
            <EmailVerify
              {...props}
              loginMode={LOGIN_MODES[props.match.params.mode]}
              title={'Verify Email'}
            />
          )}
        />

        {/* <Route
          exact
          path="/company"
          component={() => renderWithLayout(<Login {...props} loginMode={LOGIN_MODES["company"]} />,"company")}
        /> */}

        <Route
          exact
          path="/admin"
          component={() =>
            renderWithLayout(
              <Login
                {...props}
                loginMode={LOGIN_MODES['admin']}
                title={'Admin Login'}
              />,
              'admin'
            )
          }
        />

        <Route
          exact
          path="/pay"
          component={() => (
            <PayAuth loginMode={LOGIN_MODES['pay']} {...props} />
          )}
        />

        <Route
          exact
          path="/pay/employer-signup"
          component={() => (
            <PayWebSignup loginMode={LOGIN_MODES['pay']} {...props} />
          )}
        />

        <Route
          exact
          path="/company"
          component={() =>
            renderWithLayout(
              <Login
                {...props}
                loginMode={LOGIN_MODES['company']}
                title={'Company Login'}
              />,
              'company'
            )
          }
        />
        {/* ------------------ */}

        <Route path="/404" component={() => <PageNotFound {...props} />} />
        <Route path="/500" component={() => <InternalError {...props} />} />
        <Route
          path="/under_maintenance"
          component={() => <UnderMaintenance {...props} />}
        />
        <Redirect to="/pay" />
      </Switch>
    </BrowserRouter>
  );
}

export default withRouter(Router);
