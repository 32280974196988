import React from 'react';

function TwoFactorEmail() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="23"
      height="24"
      fill="none"
      viewBox="0 0 23 24"
    >
      <path
        stroke="#292D32"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="1.5"
        d="M16.292 20.5H6.708c-2.875 0-4.791-1.5-4.791-5v-7c0-3.5 1.916-5 4.791-5h9.584c2.875 0 4.791 1.5 4.791 5v7c0 3.5-1.916 5-4.791 5z"
      ></path>
      <path
        stroke="#292D32"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="1.5"
        d="M16.292 9l-3 2.5c-.987.82-2.607.82-3.594 0L6.708 9"
      ></path>
    </svg>
  );
}

export default TwoFactorEmail;
