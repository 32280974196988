import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    marginLeft: '-11px',
    marginTop: '-6px',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
});

export default function TermAndPrivicyCheckbox(props) {
  const classes = useStyles();
  var classname = 'd-flex sk-tc-accpt-ctnr';
  classname += props.className ? ' ' + props.className : '';
  var action_name = props.action_name ? props.action_name : 'Sign up';
  return (
    <div className={classname}>
      <div>
        <Checkbox {...props} className={classes.root} />
      </div>
      <div className="sk-tc-accpt-txt">
        By clicking {action_name}, you agree to our{' '}
        <a
          href="https://www.skuad.io/privacy"
          rel="noopener noreferrer"
          target="_blank"
        >
          Terms and Privacy Policy
        </a>{' '}
        .
      </div>
    </div>
  );
}
