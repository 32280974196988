import { validateFormInput } from '../../../utils/generic';

/** **************ACTION TYPES*********************** **/
export const ActionTypes = {
  UpdateUserRole: 'UPDATE_USER_ROLE',
  UpdateUserType: 'UPDATE_USER_TYPE',
  UpdateOpenAdditionalInfo: 'UPDATE_OPEN_ADDITIONAL_INFO',
  UpdateInvited: 'UPDATE_INVITED',
  UpdateMetaObject: 'UPDATE_META_OBJECT',
  UpdateFormData: 'UPDATE_FORM_DATA',
  UpdateErrorData: 'UPDATE_ERROR_DATA',
  DeleteErrorData: 'DELETE_ERROR_DATA',
  UpdateCaptchaToken: 'UPDATE_CAPTCHA_TOKEN',
};

/** **************INITIAL STATES*********************** **/

export const PayAuthInitialState = {
  userRole: '',
  userType: '',
  openAdditionalInfo: false,
  invited: false,
  metaObject: {},
  formData: {
    fullName: '',
    companyName: '',
    email: '',
    password: '',
    confirmPassword: '',
    newsLetterSubscription: [],
  },
  errorData: {},
  captchaToken: '',
};

/** **************REDUCER*********************** **/
export const reducer = (state, action) => {
  switch (action.type) {
    case ActionTypes.UpdateUserRole:
      return {
        ...state,
        userRole: action.payload,
      };
    case ActionTypes.UpdateUserType:
      return {
        ...state,
        userType: action.payload,
      };
    case ActionTypes.UpdateOpenAdditionalInfo:
      return {
        ...state,
        openAdditionalInfo: action.payload,
      };
    case ActionTypes.UpdateInvited:
      return {
        ...state,
        invited: action.payload,
      };
    case ActionTypes.UpdateMetaObject:
      return {
        ...state,
        metaObject: {
          ...action.payload,
        },
      };
    case ActionTypes.UpdateFormData:
      return {
        ...state,
        formData: {
          ...state.formData,
          ...action.payload,
        },
      };
    case ActionTypes.UpdateErrorData: {
      const errorObject = validateFormInput(action.payload, state.formData);
      return {
        ...state,
        errorData: {
          ...state.errorData,
          ...errorObject,
        },
      };
    }
    case ActionTypes.DeleteErrorData: {
      const errorData = { ...state.errorData };
      delete errorData[action.payload];
      return {
        ...state,
        errorData: {
          ...errorData,
        },
      };
    }
    case ActionTypes.UpdateCaptchaToken:
      return {
        ...state,
        captchaToken: action.payload,
      };
    default:
      return state;
  }
};
