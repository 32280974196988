import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ErrorPage from '../components/error/InviteExpired';
import { getInviteFunction } from '../api';
import * as actions from '../redux/actions/appAction';

const Invite = props => {
  const [showError, setShowError] = useState(true);
  useEffect(() => {
    props.startPageLoader();
    let id = props.match.params.id;
    if (!id) setShowError(true);
    (async () => {
      try {
        let invite = await getInviteFunction(id);
        if (invite && invite.redirectUrl)
          window.location.replace(`${invite.redirectUrl}&invite_id=${id}`);
        else setShowError(true);
        props.stopPageLoader();
      } catch (error) {
        setShowError(true);
        props.stopPageLoader();
      }
    })();
  }, []);
  return (
    <>
      {showError && (
        <>
          {' '}
          <ErrorPage
            header={true}
            {...props}
            errMessage={'INIVITE HAS BEEN EXPIRED!'}
          />
        </>
      )}
    </>
  );
};

const mapStateToProps = state => ({
  ...state,
});
const mapDispatchToProps = {
  ...actions,
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Invite));
