import React, { useState } from 'react';
import {
  FormControl,
  makeStyles,
  TextField,
  FormLabel,
  InputAdornment,
  IconButton,
  Box,
  Card,
  FormControlLabel,
  Radio,
} from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import CustomTooltip from './CustomTooltip';
import MuiPhoneNumber from '../forms/MuiPhoneNumber';
import Autocomplete from '@material-ui/lab/Autocomplete';

const useStyles = makeStyles({
  label: {
    fontWeight: '600',
    fontSize: '12px',
    lineHeight: '16px',
    color: 'var(--sk-black) !important',
    marginBottom: '13px',
  },
  radioLabel: {
    fontWeight: '600',
    fontSize: '18px',
    lineHeight: '20px',
    color: 'var(--sk-black)',
  },
  asterisk: {
    color: 'red',
  },
  textFieldRoot: {
    '& > .MuiOutlinedInput-root': {
      borderRadius: '5px',
    },
    '& > .Mui-focused': {},
  },
  selectLabel: {
    fontSize: '16px',
    lineHeight: '22px',
    color: '#000000',
    opacity: '0.4',
    padding: '6px 16px',
    margin: 'unset',
  },
  radioCard: {
    padding: '34px 30px 30px 32px',
    width: '100%',
    boxShadow: '0px 6px 12px rgba(10, 4, 60, 0.15)',
    borderRadius: '10px',
  },
  radioTextInputWrapper: {
    marginLeft: '28px',
    marginTop: '8px',
  },
  error: {
    color: 'transparent',
  },
  dropdownInputRoot: {
    height: '50px',
    padding: '0px !important',
  },
});

const FormInput = ({
  id,
  label,
  placeholder,
  value,
  handleChange,
  handleDropdownChange,
  dropdownInputValue,
  dropdownInputChange,
  handlePhoneInputChange,
  required,
  type = 'text',
  tooltipText,
  name,
  radioLabel,
  radioValue,
  radioName,
  radioSelected,
  radioInputType,
  formControlError,
  menuList,
  ...props
}) => {
  const classes = useStyles();

  const [showPassword, setShowPassword] = useState(false);

  const renderFormInput = () => {
    switch (type) {
      case 'password':
        return (
          <TextField
            {...props}
            fullWidth
            required={required}
            type={showPassword ? 'text' : 'password'}
            value={value}
            name={name}
            onChange={handleChange}
            variant="outlined"
            placeholder={placeholder}
            classes={{
              root: classes.textFieldRoot,
            }}
            FormHelperTextProps={{ classes: { root: classes.error } }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowPassword(show => !show)}
                    edge="end"
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            color="secondary"
            autoComplete="off"
          />
        );
      case 'dropdown':
        return (
          <Autocomplete
            {...props}
            id={id}
            size="small"
            value={value}
            inputValue={dropdownInputValue}
            onInputChange={dropdownInputChange}
            onChange={handleDropdownChange}
            options={menuList}
            getOptionLabel={option => option.label || ''}
            renderOption={option => (
              <React.Fragment>
                {option.label} ({option.value})
              </React.Fragment>
            )}
            renderInput={params => (
              <TextField
                {...params}
                label={placeholder}
                variant="outlined"
                inputProps={{
                  ...params.inputProps,
                  autoComplete: 'new-password', // disable autocomplete and autofill
                }}
              />
            )}
            classes={{
              inputRoot: classes.dropdownInputRoot,
            }}
          />
        );
      case 'phone':
        return (
          <MuiPhoneNumber
            {...props}
            value={value}
            name={name}
            onChange={handlePhoneInputChange}
            variant="outlined"
            id={id}
          />
        );

      default:
        return (
          <TextField
            {...props}
            required={required}
            fullWidth
            type={type}
            value={value}
            name={name}
            onChange={handleChange}
            variant="outlined"
            placeholder={placeholder}
            classes={{
              root: classes.textFieldRoot,
            }}
            onBlur={e => {
              e.target.value = e.target.value.trim();
              handleChange && handleChange(e);
            }}
            color="secondary"
            autoComplete="off"
          />
        );
    }
  };
  return (
    <FormControl fullWidth variant="outlined" error={formControlError}>
      {radioInputType ? (
        <Card className={classes.radioCard}>
          <FormControlLabel
            value={radioValue}
            name={radioName}
            control={<Radio color="secondary" />}
            label={radioLabel}
            classes={{
              label: classes.radioLabel,
            }}
          />
          {radioSelected === radioValue && (
            <Box className={classes.radioTextInputWrapper}>
              <Box>
                <FormLabel
                  required={required}
                  htmlFor={id}
                  classes={{
                    root: classes.label,
                    asterisk: classes.asterisk,
                  }}
                  color="secondary"
                >
                  {label}
                </FormLabel>
                {tooltipText && <CustomTooltip text={tooltipText} />}
              </Box>
              {renderFormInput()}
            </Box>
          )}
        </Card>
      ) : (
        <>
          <Box>
            {label && (
              <FormLabel
                required={required}
                htmlFor={id}
                classes={{
                  root: classes.label,
                  asterisk: classes.asterisk,
                }}
                color="primary"
              >
                {label}
              </FormLabel>
            )}
            {tooltipText && <CustomTooltip text={tooltipText} />}
          </Box>
          {renderFormInput()}
        </>
      )}
    </FormControl>
  );
};

export default FormInput;
