import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import { makeStyles } from '@material-ui/core/styles';
import * as _ from 'lodash';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { FormHelperText, Tooltip } from '@material-ui/core';
const useStyles = makeStyles({
  root: {
    marginLeft: '5px',
    marginTop: '5px',
    '&:hover': {
      backgroundColor: 'transparent',
    },
    display: 'flex',
  },
  icon: {
    fontSize: '1rem',
    margin: 'auto .25rem auto 0',
  },
  mtauto: {
    margin: 'auto 0',
  },
});

export default function ErrorText(props) {
  const classes = useStyles();
  return (
    <>
      {_.get(props.error, [props.name], '') != '' && (
        <div className={classes.root}>
          <Tooltip title={props.tooltip}>
            <ErrorOutlineIcon
              fontSize={'small'}
              color={'primary'}
              className={classes.icon}
            />
          </Tooltip>
          <FormHelperText
            component={'div'}
            className={classes.mtauto}
            error={true}
          >
            {_.get(props.error, [props.name], '')}
          </FormHelperText>
        </div>
      )}
    </>
  );
}
