import React from 'react';
import {
  Card,
  CardContent,
  Button,
  Typography,
  Box,
  IconButton,
} from '@material-ui/core';
import CardWithTitle from '../designSystem/CardWithTitle';

import ContentCopyIcon from '@material-ui/icons/FileCopyOutlined';

import { showSnackbar } from '../../redux/actions/appAction';
import { useDispatch } from 'react-redux';

const AuthenticatorSetup = ({
  state,
  dispatch,
  two2FASetupObject,
  onBack,
  onSubmit,
}) => {
  const _dispatch = useDispatch();

  return (
    <form onSubmit={onSubmit}>
      <CardWithTitle title="Setup Two-Factor Authentication (2FA)">
        <Card>
          <CardContent style={{ padding: 24 }}>
            <Typography
              align="center"
              variant="body1"
              style={{ fontWeight: '600', marginBottom: 16 }}
            >
              Open your Authenticator app and scan this QR Code
            </Typography>
            <img
              src={two2FASetupObject?.qrCodeUrl}
              alt="QR Code"
              style={{ display: 'block', margin: '10px auto' }}
            />
            <Typography
              align="center"
              variant="body1"
              style={{ fontWeight: '600', marginBottom: 16, marginTop: 16 }}
            >
              or manually enter the following code
            </Typography>
            <Typography
              align="center"
              variant="body1"
              style={{ fontWeight: '600', marginBottom: 16 }}
            >
              {two2FASetupObject?.secret}{' '}
              <IconButton
                onClick={() => {
                  navigator.clipboard
                    .writeText(two2FASetupObject?.secret)
                    .then(function () {
                      _dispatch(showSnackbar('Text copied to clipboard'));
                    })
                    .catch(function (error) {
                      console.error('Error copying text: ', error);
                    });
                }}
              >
                <ContentCopyIcon />
              </IconButton>
            </Typography>

            <Typography align="center" variant="caption">
              This QR code will be valid for 30 minutes
            </Typography>
          </CardContent>
        </Card>

        <Typography
          variant="body1"
          style={{ marginTop: 24, fontWeight: '600', marginBottom: 8 }}
        >
          Download and install an authenticator app
        </Typography>
        <Typography variant="body2">
          If you already have an authenticator app, click Continue.
          <br /> If not, download any of these authenticator apps - Google
          Authenticator, Authy, Dashlane or Microsoft Authenticator and follow
          the setup instructions in the app.
        </Typography>

        <Box
          display="flex"
          style={{ marginTop: 48, gap: 24 }}
          justifyContent="flex-end"
        >
          <Button variant="outlined" color="primary" onClick={onBack}>
            Back
          </Button>
          <Button variant="contained" type="submit" color="primary">
            Continue
          </Button>
        </Box>
      </CardWithTitle>
    </form>
  );
};

export default AuthenticatorSetup;
