import React, { useState, useEffect } from 'react';
import { Typography, Link as MuiLink } from '@material-ui/core';

const formatCountdown = seconds => {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;
  return `${minutes.toString().padStart(2, '0')}:${remainingSeconds
    .toString()
    .padStart(2, '0')}`;
};

const ResendCode = ({ onClick, lastSent, disabled, seconds = 60 }) => {
  const [countdown, setCountdown] = useState(seconds);
  const [isCountingDown, setIsCountingDown] = useState(false);

  useEffect(() => {
    if (isCountingDown) {
      const timer = setInterval(() => {
        setCountdown(prevCountdown => {
          const count = prevCountdown - 1;
          if (count <= 0) {
            setIsCountingDown(false);
            clearInterval(timer);
          }
          return count;
        });
      }, 1000);
      return () => clearInterval(timer);
    }
  }, [isCountingDown]);

  const handleClick = () => {
    if (!disabled) {
      onClick();
    }
  };

  useEffect(() => {
    if (lastSent) {
      setIsCountingDown(true);
      setCountdown(seconds);
    }
  }, [lastSent]);

  return (
    <>
      {isCountingDown ? (
        <Typography variant="label" style={{ cursor: 'default' }}>
          Resend Code in{' '}
          <span style={{ fontWeight: 600 }}>{formatCountdown(countdown)}</span>
        </Typography>
      ) : (
        <MuiLink onClick={handleClick} id="form-resendCode-link">
          <Typography
            variant="label"
            fontWeight={600}
            color="primary"
            style={{ cursor: 'pointer' }}
          >
            Resend Code
          </Typography>
        </MuiLink>
      )}
    </>
  );
};

export default ResendCode;
