import React from 'react';
import MUIPhoneNumber from 'material-ui-phone-number';

const MuiPhoneNumber = props => {
  const {
    onChange,
    value,
    defaultValue,
    defaultCountry: _defaultCountry,
    ...rest
  } = props;

  const _onChange = (value, data) => {
    let out = value.replace(/[^0-9+]/g, '');
    let dialCode = '+' + data.dialCode;
    if (out.length < dialCode.length || out === dialCode) {
      out = '+';
    }
    if (onChange)
      onChange({
        target: {
          name: props.name,
          value: out,
        },
      });
  };
  return (
    <MUIPhoneNumber
      {...rest}
      value={value}
      disableAreaCodes={true}
      defaultCountry={_defaultCountry?.toLowerCase() || 'us'}
      onChange={_onChange}
    />
  );
};

export default MuiPhoneNumber;
