import React from 'react';
import { Box, Typography, Grid } from '@material-ui/core';
import IdhTech from '../../../images/IdhTech.svg';
import SleekTech from '../../../images/SleekTech.svg';
import GlobalMarket from '../../../images/MarketGroup.svg';
import SensorFlow from '../../../images/SensorFlow.svg';
import InsightGlobal from '../../../images/InsightGlobal.svg';
import Ninjavan from '../../../images/Ninjvan.svg';

const images = [
  {
    index: 1,
    alt: 'Insight Global',
    src: InsightGlobal,
  },
  {
    index: 2,
    alt: 'Ninjavan Tech',
    src: Ninjavan,
  },
  {
    index: 3,
    alt: 'Idh Tech',
    src: IdhTech,
  },
  {
    index: 4,
    alt: 'Sensor Flow',
    src: SensorFlow,
  },
  {
    index: 5,
    alt: 'Global Market',
    src: GlobalMarket,
  },
  {
    index: 6,
    alt: 'Sleek Tech',
    src: SleekTech,
  },
];

const TrustedCompaniesList = ({
  headingProps,
  wrapperBoxProps,
  itemGridProps,
  showLogoOnDashBoard = true,
}) => {
  return (
    <Box {...wrapperBoxProps}>
      <Typography variant="h3" {...headingProps}>
        Companies that trust us for remote hiring & global payroll
      </Typography>
      <Box marginTop={3}>
        <Grid container spacing={2}>
          {images.map(image => (
            <Grid item key={image.index} sm={2} md={5} {...itemGridProps}>
              <Box textAlign="center" borderRadius={4}>
                <img
                  src={image.src}
                  alt={image.alt}
                  width={showLogoOnDashBoard && '220px'}
                />
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
};

export default TrustedCompaniesList;
