import React from 'react';
import PropTypes from 'prop-types';
import {
  Backdrop,
  Box,
  Card,
  CardContent,
  Button,
  Typography,
  Divider,
  Link,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import DoneIcon from './icons/DoneIcon';

const useStyles = makeStyles(theme => ({
  enableText: {
    fontWeight: 600,
    marginBottom: theme.spacing(1),
  },
  instructionTopText: {
    fontWeight: 600,
    marginBottom: theme.spacing(4),
    textAlign: 'center',
  },
  instructionBottomText: {
    marginBottom: theme.spacing(4),
    textAlign: 'center',
  },
  buttonContainer: {
    marginTop: theme.spacing(6),
    gap: theme.spacing(3),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'baseline',
  },
  backButton: {
    marginRight: theme.spacing(2),
  },
  card: {
    borderRadius: theme.spacing(1),
    maxWidth: 600,
  },
  emailSentMessage: {
    marginBottom: theme.spacing(5.5),
  },
  bottom: {
    fontSize: 12,
    textAlign: 'center',
  },
  backdrop: {
    backgroundColor: '#FEFAFA',
    zIndex: 2000,
  },
}));

const EmailVerifiedBackdrop = ({
  onContinueClick,
  heading = 'Your email has been successfully verified.',
  subHeading = " Welcome to Skuad! To help you get started, let's set up your profile for a tailored experience.",
  btnText = "Let's Get Started",
  icon = <DoneIcon />,
  showContactInfo = false,
}) => {
  const classes = useStyles();

  return (
    <Backdrop className={classes.backdrop} open={true}>
      <Card className={classes.card}>
        <CardContent>
          <Box display="flex" flexDirection="column" alignItems="center">
            <Box mb={3}>{icon}</Box>
            <Typography
              variant="h5"
              gutterBottom
              className={classes.enableText}
              align="center"
            >
              {heading}
            </Typography>
            <Typography
              variant="body1"
              gutterBottom
              align="center"
              className={classes.emailSentMessage}
            >
              {subHeading}
            </Typography>
            <Box
              display="flex"
              justifyContent="center"
              width="100%"
              onClick={onContinueClick}
            >
              <Button variant="contained" color="primary">
                {btnText}
              </Button>
            </Box>
          </Box>
          {showContactInfo && (
            <Box>
              <Divider style={{ margin: 30 }} />
              <Typography className={classes.bottom} align='center'>
                If you continue to experience issues, please contact our support
                team at <Link href="mailto:csm@skuad.io">csm@skuad.io</Link>.
              </Typography>
            </Box>
          )}
        </CardContent>
      </Card>
    </Backdrop>
  );
};

EmailVerifiedBackdrop.propTypes = {
  email: PropTypes.string.isRequired,
  heading: PropTypes.string,
  subHeading: PropTypes.string,
};

export default EmailVerifiedBackdrop;
