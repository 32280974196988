import { themeConstants } from './constants';

const palette = {
  primary: themeConstants.palette?.primary,
  secondary: themeConstants.palette?.secondary,
  info: themeConstants.palette?.info,
  warning: themeConstants.palette?.warning,
  success: themeConstants.palette?.success,
  error: themeConstants.palette?.error,
  text: themeConstants.palette?.text,
  background: themeConstants.palette?.background,
  common: themeConstants.palette?.common,
  grey: themeConstants.palette?.grey,
};

export default palette;
