import { createTheme } from '@material-ui/core/styles';
import { themeConstants } from './constants';

const defaultTheme = createTheme();

const overrides = {
  MuiButton: {
    text: {
      textTransform: 'none',
    },
    root: {
      ...themeConstants.typography?.body2,
      fontWeight: themeConstants.typography?.fontWeightSemiBold,
      textTransform: 'none',
      wordBreak: 'break-word',
      minHeight: defaultTheme.spacing(6),
      padding: `${defaultTheme.spacing(1)}px ${defaultTheme.spacing(2)}px`,
      boxShadow: 'none',
      '&:hover': {
        boxShadow: 'none !important',
      },
    },
    contained: {
      boxShadow: 'none',
      '&:hover': {
        boxShadow: 'none !important',
      },
    },
  },
  MuiCard: {
    root: {
      borderRadius: defaultTheme.spacing(1),
    },
  },
  MuiCardContent: {
    root: {
      height: '100%',
      padding: defaultTheme.spacing(5),
      '&:last-child': {
        paddingBottom: defaultTheme.spacing(5),
      },
    },
  },
  MuiFormLabel: {
    root: {
      ...themeConstants.typography?.body2,
      fontWeight: themeConstants.typography?.fontWeightSemiBold,
      color: themeConstants.palette?.text.primary,
      '&.Mui-error': {
        color: themeConstants.palette?.error?.main,
      },
    },
    asterisk: {
      color: themeConstants.palette?.error?.main,
    },
  },
  MuiInputBase: {
    root: {
      color: themeConstants.palette?.text.default,
      '&.Mui-disabled': {
        '-webkit-text-fill-color': themeConstants.palette.text.disabled,
      },
    },
  },
  MuiTypography: {
    root: {
      color: themeConstants.palette?.text.main,
    },
    gutterBottom: {
      marginBottom: defaultTheme.spacing(0.5),
    },
  },
};

export default overrides;
