import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  ctnr: {
    position: 'relative',
    zIndex: 1,
    marginTop: 'auto',
    [theme.breakpoints.up('xxl')]: {
      height: '100%',
    },
    [theme.breakpoints.down('sm')]: {
      position: 'static',
      display: 'flex',
      justifyContent: 'end',
    },
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
      justifyContent: 'center',
      paddingTop: '70px',
    },
  },
  heroImage: {
    height: '380px',
    marginLeft: '-30px',
    [theme.breakpoints.up('xxl')]: {
      height: '100%',
    },
    [theme.breakpoints.down('md')]: {
      height: '300px',
      top: '34px',
      margin: 0,
    },
    [theme.breakpoints.down('sm')]: {
      position: 'static',
      height: '220px',
    },
    [theme.breakpoints.down('xs')]: {
      position: 'static',
      height: '150px',
      margin: '-30px',
    },
  },
}));

const HeroImage = () => {
  const classes = useStyles();
  return (
    <div className={classes.ctnr}>
      <img src="/heroimage.svg" alt="hero" className={classes.heroImage} />
    </div>
  );
};

export default HeroImage;
