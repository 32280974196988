import { themeConstants } from './constants';

const typography = {
  ...themeConstants.typography,
  h1: {
    ...themeConstants.typography?.h1,
    fontSize: `${themeConstants.typography?.h1?.fontSize} !important`,
  },
  h2: {
    ...themeConstants.typography?.h1,
    fontSize: `${themeConstants.typography?.h1?.fontSize} !important`,
  },
  h3: {
    ...themeConstants.typography?.h3,
    fontSize: `${themeConstants.typography?.h3?.fontSize} !important`,
  },
  h4: {
    ...themeConstants.typography?.h4,
    fontSize: `${themeConstants.typography?.h4?.fontSize} !important`,
  },
  subtitle1: {
    ...themeConstants.typography?.subtitle1,
    fontSize: `${themeConstants.typography?.subtitle1?.fontSize} !important`,
  },
  body1: {
    ...themeConstants.typography?.body1,
    // fontSize: `${themeConstants.typography?.body1?.fontSize} !important`,
  },
  body2: {
    ...themeConstants.typography?.body2,
    fontSize: `${themeConstants.typography?.body2?.fontSize} !important`,
  },
  caption: {
    ...themeConstants.typography?.caption,
    fontSize: `${themeConstants.typography?.caption?.fontSize} !important`,
  },
};

export default typography;
