import React, { useState, useEffect } from 'react';
import { makeStyles, Box, Typography } from '@material-ui/core';
import ThankyouText from '../common/ThankyouText';
import FormInput from '../common/FormInput';
import CustomCard from '../common/CustomCard';
import CustomButton from '../common/CustomButton';
import EmailVerifiedBackdrop from '../NewForms/EmailVerifiedBackdrop';

const useStyles = makeStyles({
  emailVerifyWrapper: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

const EmailVerifyOutput = props => {
  const classes = useStyles();

  useEffect(() => {
    props.setView && props.setView('email-verify');
  }, [props]);

  const [password, setPassword] = useState('');

  const continueClick = async () => {
    window.location.replace(`${decodeURIComponent(props.redirectURL)}`);
  };

  const submitVerification = e => {
    e.preventDefault();
    if (password) {
      props.onSubmit && props.onSubmit({ password });
    }
  };

  return (
    <Box className={classes.emailVerifyWrapper}>
      {!props.isSecondaryEmail && (
        <EmailVerifiedBackdrop onContinueClick={continueClick} />
      )}

      {props.isSecondaryEmail && (
        <CustomCard width="100%">
          <form onSubmit={submitVerification}>
            <Box textAlign="left" pb={2}>
              <Typography variant="h2" component={Box} mb={2}>
                Email verification
              </Typography>
            </Box>
            <Box>
              <FormInput
                label="Create new password"
                handleChange={e => setPassword(e.target.value)}
                type="password"
                required
              />
            </Box>
            <Box mt={4}>
              <CustomButton
                variant="contained"
                color="primary"
                custom
                type="submit"
              >
                Continue
              </CustomButton>
            </Box>
          </form>
        </CustomCard>
      )}
    </Box>
  );
};

export default EmailVerifyOutput;
