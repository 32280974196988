import React from 'react';

function EmailIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="154"
      height="151"
      fill="none"
      viewBox="0 0 154 151"
    >
      <rect
        width="149.961"
        height="149.645"
        x="0.52"
        y="0.9"
        fill="#13B68F"
        fillOpacity="0.05"
        rx="74.823"
      ></rect>
      <path
        fill="#EFFAF7"
        stroke="#13B68F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="1.6"
        d="M105.277 99.346H45.723a5.203 5.203 0 01-5.203-5.203v-36.84a5.203 5.203 0 015.203-5.202h59.554a5.203 5.203 0 015.203 5.203v36.838a5.203 5.203 0 01-5.203 5.204z"
      ></path>
      <path
        stroke="#13B68F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="1.6"
        d="M42.327 53.602l30.471 27.706a4.016 4.016 0 005.403 0l30.471-27.706M66.656 75.723l-24.329 22.12M108.672 97.843l-24.328-22.12"
      ></path>
      <g filter="url(#filter0_dd_170_51022)">
        <rect width="68" height="21" x="76" y="41" fill="#EFFAF7" rx="4"></rect>
      </g>
      <path
        fill="#13B68F"
        d="M87.031 54.91l.13-2.302-1.931 1.267-.693-1.205 2.062-1.034-2.062-1.034.693-1.204 1.932 1.267-.131-2.301h1.38l-.124 2.3 1.931-1.266.694 1.204-2.069 1.034 2.069 1.034-.694 1.205-1.931-1.267.125 2.301H87.03zm8.712 0l.13-2.302-1.932 1.267-.693-1.205 2.063-1.034-2.063-1.034.693-1.204 1.932 1.267-.13-2.301h1.38l-.125 2.3 1.932-1.266.693 1.204-2.068 1.034 2.068 1.034-.693 1.205-1.932-1.267.125 2.301h-1.38zm8.711 0l.131-2.302-1.932 1.267-.693-1.205 2.062-1.034-2.062-1.034.693-1.204 1.932 1.267-.131-2.301h1.381l-.125 2.3 1.932-1.266.693 1.204-2.068 1.034 2.068 1.034-.693 1.205-1.932-1.267.125 2.301h-1.381zm8.712 0l.13-2.302-1.932 1.267-.693-1.205 2.063-1.034-2.063-1.034.693-1.204 1.932 1.267-.13-2.301h1.38l-.125 2.3 1.932-1.266.693 1.204-2.068 1.034 2.068 1.034-.693 1.205-1.932-1.267.125 2.301h-1.38zm8.711 0l.131-2.302-1.932 1.267-.693-1.205 2.062-1.034-2.062-1.034.693-1.204 1.932 1.267-.131-2.301h1.381l-.125 2.3 1.932-1.266.693 1.204-2.068 1.034 2.068 1.034-.693 1.205-1.932-1.267.125 2.301h-1.381zm8.712 0l.13-2.302-1.932 1.267-.693-1.205 2.063-1.034-2.063-1.034.693-1.204 1.932 1.267-.13-2.301h1.38l-.125 2.3 1.932-1.266.693 1.204-2.068 1.034 2.068 1.034-.693 1.205-1.932-1.267.125 2.301h-1.38z"
      ></path>
      <defs>
        <filter
          id="filter0_dd_170_51022"
          width="88"
          height="47"
          x="66"
          y="31"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="6"></feOffset>
          <feGaussianBlur stdDeviation="5"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 0.345098 0 0 0 0 0.447059 0 0 0 0 0.537255 0 0 0 0.2 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_170_51022"
          ></feBlend>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="-2"></feOffset>
          <feGaussianBlur stdDeviation="4"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 0.345098 0 0 0 0 0.447059 0 0 0 0 0.537255 0 0 0 0.1 0"></feColorMatrix>
          <feBlend
            in2="effect1_dropShadow_170_51022"
            result="effect2_dropShadow_170_51022"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect2_dropShadow_170_51022"
            result="shape"
          ></feBlend>
        </filter>
      </defs>
    </svg>
  );
}

export default EmailIcon;
