import {
  Box,
  Card,
  CardContent,
  Divider,
  Grid,
  Typography,
} from '@material-ui/core';
import React, { useReducer } from 'react';
import * as _ from 'lodash';
import AuthenticationCTAs from '../../common/AuthenticationCTAs/AuthenticationCTAs';

import { ActionTypes, ResetPassWordInitialState, reducer } from './reducer';
import PasswordInput from '../../common/PasswordInput/PasswordInput';
import { confirmPasswordReset } from '../../firebase/helper';
import { validateFields } from '../../../utils/validator';
import ThankyouText from '../../common/ThankyouText';
import { useDispatch } from 'react-redux';
import {
  startPageLoader,
  stopPageLoader,
} from '../../../redux/actions/appAction';
import ErrorText from '../../common/ErrorText';
import { PASSWORD_SUGGESTION_MESSAGE } from '../../../utils/constants';

const ResetPasswordForm = (code, redirect_url, props) => {
  const _dispatch = useDispatch();
  const [state, dispatch] = useReducer(reducer, ResetPassWordInitialState);

  const onChangeHandler = event => {
    dispatch({ type: ActionTypes.SetPasswordError, payload: {} });
    dispatch({ type: ActionTypes.UpdatePassword, payload: event.target.value });
  };

  const validateForm = () => {
    let error = {};
    let isValidate = true;

    let validatePassword = validateFields('password', state.password);

    if (validatePassword) {
      _.set(error, 'password', validatePassword);
    }

    if (!_.isEmpty(error)) {
      isValidate = false;
    }
    dispatch({ type: ActionTypes.SetPasswordError, payload: error });
    return isValidate;
  };

  const handleSubmit = async function (e) {
    e.preventDefault();
    if (validateForm()) {
      _dispatch(startPageLoader());
      try {
        await confirmPasswordReset(code?.code, state.password);
        _dispatch(stopPageLoader());
        dispatch({ type: ActionTypes.ResetPasswordSuccess, payload: true });
      } catch (error) {
        dispatch({ type: ActionTypes.ResetPasswordSuccess, payload: false });
        _dispatch(stopPageLoader());
        return;
      }
    }
  };

  const continueClick = async () => {
    window.location.replace(`${decodeURIComponent(redirect_url)}`);
  };

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      minHeight="100vh"
    >
      {state.isResetPasswordSuccess ? (
        <ThankyouText
          {...props}
          heading="Password reset successfully"
          continueClick={continueClick}
        />
      ) : (
        <Card>
          <CardContent>
            <form
              noValidate
              onSubmit={e => handleSubmit(e)}
              id="form-resetPassword"
            >
              <Grid container spacing={5}>
                <Grid item xs={12}>
                  <Typography variant="subtitle1">
                    Enter your new password
                  </Typography>
                  <Divider />
                </Grid>
                <Grid item xs={12}>
                  <PasswordInput
                    id="form-resetPassword-passwordInput"
                    name="password"
                    label="Password"
                    required
                    value={state.password}
                    onChange={onChangeHandler}
                    error={
                      !_.isEmpty(_.get(state.passwordError, ['password'], ''))
                    }
                  />
                  {!_.isEmpty(_.get(state.passwordError, ['password'], '')) ? (
                    <ErrorText
                      error={state.passwordError}
                      name={'password'}
                      tooltip={`${PASSWORD_SUGGESTION_MESSAGE}`}
                    />
                  ) : (
                    <Typography variant="body2" color="textSecondary">
                      {PASSWORD_SUGGESTION_MESSAGE}
                    </Typography>
                  )}
                </Grid>
                <AuthenticationCTAs
                  onResetPasswordButtonProps={{
                    disabled: !state.password,
                    id: 'form-resetPassword-submitCTA',
                  }}
                />
              </Grid>
            </form>
          </CardContent>
        </Card>
      )}
    </Box>
  );
};

export default ResetPasswordForm;
