import React from 'react';
import clsx from 'clsx';
import { Box, Card, makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    padding: '30px',
    boxShadow: '0px 6px 12px rgba(10, 4, 60, 0.15)',
    borderRadius: '10px',
  },
});

function CustomCard({ component, children, ...rest }) {
  const classes = useStyles();

  return (
    <Box
      {...rest}
      component={component}
      className={clsx(classes.root, rest?.className, 'custom-card')}
    >
      {children}
    </Box>
  );
}

CustomCard.defaultProps = {
  component: Card,
};

export default CustomCard;
