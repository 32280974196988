import React from 'react';
import { connect } from 'react-redux';
import Snackbar from '@material-ui/core/Snackbar';
import Fade from '@material-ui/core/Fade';
import * as action from '../../redux/actions/appAction';

const SnackbarLoader = props => {
  const closeSnackbar = () => {
    props.hideSnackbar();
  };
  return (
    <Snackbar
      open={props.snackbarVisible}
      autoHideDuration={3000}
      onClose={closeSnackbar}
      onClick={closeSnackbar}
      TransitionComponent={Fade}
      message={props.snackbarMessage}
      className="text-center"
      style={{
        zIndex: 10000
      }}
    />
  );
};

const mapStateToProps = state => ({
  snackbarVisible: state.app.snackbarVisible,
  snackbarMessage: state.app.snackbarMessage,
});

export default connect(mapStateToProps, action)(SnackbarLoader);
