import React from 'react';
import { Box, styled } from '@material-ui/core';

import SkuadBlackLogo from '../../../images/skuad-logo-black.svg';

const StyledBox = styled(Box)(({ theme, formLayout }) => ({
  [theme.breakpoints.up('md')]: {
    ...(formLayout && {
      position: 'fixed',
      top: theme.spacing(0),
      left: theme.spacing(0),
    }),
  },
  [theme.breakpoints.down('sm')]: {
    textAlign: 'center',
  },
}));

const Header = ({ formLayout }) => {
  return (
    <StyledBox padding={4} formLayout={formLayout}>
      <img src={SkuadBlackLogo} alt="Skuad" />
    </StyledBox>
  );
};

export default Header;
