import { stringify, parse } from 'query-string';
import {
  ERROR_MESSAGES,
  MIN_PASSWORD_LENGTH,
  USER_ROLE,
  USER_TYPE,
  USER_TYPE_MAPPING,
  USER_TYPES,
  USER_ROLES,
  validations,
} from './constants';

export const getURLWithQueryParams = (base, params) => {
  const query = Object.entries(params)
    .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
    .join('&');

  return `${base}?${query}`;
};

export const queryToObject = queryString => {
  const pairsString =
    queryString[0] === '?' ? queryString.slice(1) : queryString;
  const pairs = pairsString
    .split('&')
    .map(str => str.split('=').map(decodeURIComponent));
  return pairs.reduce(
    (acc, [key, value]) => (key ? { ...acc, [key]: value } : acc),
    {}
  );
};

export const payGetUserType = userType => {
  switch (userType) {
    case USER_TYPE.Employer:
      return USER_ROLE.Company;
    case USER_TYPE.Contractor:
    case USER_TYPE.Employee:
      return USER_ROLE.Individual;
    default:
      return null;
  }
};

export const payGetUserRole = userType => {
  switch (userType) {
    case USER_TYPE.Employer:
      return USER_ROLE.Company;
    case USER_TYPE.Contractor:
    case USER_TYPE.Employee:
      return USER_ROLE.Individual;
    default:
      return null;
  }
};

export const payGetUserTypeByRole = userRole => {
  switch (userRole) {
    case USER_ROLE.Individual:
      return USER_TYPE.Contractor;
    case USER_ROLE.Company:
      return USER_TYPE.Employer;
    default:
      return null;
  }
};

export const payCreateUserMeta = (user = {}, rest = {}) => {
  const meta = { ...rest };
  if (user?.type) {
    meta['type'] = user?.type;
  }
  if (user?.role) {
    meta['role'] = user?.role;
  }
  if (user?.role === USER_ROLE.Company) {
    meta['companyName'] = rest?.companyName;
  }

  return meta;
};

export const getUserTypeByType = userType => {
  switch (userType) {
    case USER_TYPE_MAPPING.Employer:
      return USER_TYPES.Client;
    case USER_TYPE_MAPPING.Contractor:
      return USER_TYPES.Contractor;
    case USER_TYPE_MAPPING.Employee:
      return USER_TYPES.Employee;
    default:
      return USER_TYPES.Contractor;
  }
};

export const getUserRoleByType = userType => {
  switch (userType) {
    case USER_TYPES.Client:
      return USER_ROLES.Company;
    case USER_TYPES.Contractor:
    case USER_TYPES.Employee:
      return USER_ROLES.Individual;
    default:
      return '';
  }
};

export const passwordValidations = [
  {
    message: ERROR_MESSAGES.lowercase_character,
    validator: new RegExp(/[a-z]/),
  },
  {
    message: ERROR_MESSAGES.uppercase_character,
    validator: new RegExp(/[A-Z]/),
  },
  {
    message: ERROR_MESSAGES.numeric_character,
    validator: new RegExp(/[0-9]/),
  },
  {
    message: ERROR_MESSAGES.special_character,
    validator: new RegExp(/[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~`]/),
  },
  {
    message: ERROR_MESSAGES.invalid_password,
    validator: {
      test: pwd => pwd?.length >= MIN_PASSWORD_LENGTH,
    },
  },
];

export const blockInvalidCharOnNumberType = (event, extraChars = []) => {
  const listOfChars = ['e', 'E', '+', '-', ...extraChars];
  return listOfChars.includes(event.key) && event.preventDefault();
};

export const updateQueryParam = (queryParams = [], search, encode = true) => {
  const newQueryParameter = parse(search);
  queryParams.forEach(queryParam => {
    newQueryParameter[queryParam.parameter] = queryParam.value;
  });
  return stringify(newQueryParameter, { encode });
};

// * This function will remove the passed query params and return the new url
export const removeQueryParam = (queryParams = [], search) => {
  const newQueryParameter = parse(search);
  queryParams.forEach(queryParam => {
    delete newQueryParameter[queryParam];
  });
  return stringify(newQueryParameter);
};

export const ObjectHasKey = (errors = {}) => {
  return Object.keys(errors).length;
};

const validateByKey = (
  validationKey,
  validationSchema,
  inputField,
  formData
) => {
  let error = {};
  switch (validationKey) {
    case 'required': {
      if (validationSchema[validationKey].value && !inputField.value) {
        error = {
          [inputField.name]: validationSchema[validationKey].message,
        };
      }
      break;
    }
    case 'patterns': {
      for (const iterator of validationSchema.patterns) {
        if (!iterator.validator.test(inputField.value)) {
          error = {
            [inputField.name]: iterator.message,
          };
          break;
        }
      }
      break;
    }
    case 'minLength': {
      if (inputField.value.length < validationSchema[validationKey].value) {
        error = {
          [inputField.name]: validationSchema[validationKey].message,
        };
      }
      break;
    }
    case 'matchWithKey': {
      if (
        inputField.value !== formData[validationSchema[validationKey].value]
      ) {
        error = {
          [inputField.name]: validationSchema[validationKey].message,
        };
      }
      break;
    }
    case 'associatedFieldEquality': {
      if (
        inputField.value !== formData[validationSchema[validationKey].value]
      ) {
        error = {
          [validationSchema[validationKey].value]:
            validationSchema[validationKey].message,
        };
      } else if (
        inputField.value === formData[validationSchema[validationKey].value]
      ) {
        delete error[validationSchema[validationKey].value];
        // if both the current field and associated fields are same, delete error of associated fields
      }
      break;
    }
    default:
      break;
  }
  return error;
};

export const validateFormInput = (inputField, formData) => {
  let error = {};
  const validationSchema = validations[inputField.name];
  for (const key in validationSchema) {
    const errorByKey = validateByKey(
      key,
      validationSchema,
      inputField,
      formData
    );
    if (ObjectHasKey(errorByKey)) {
      return errorByKey;
    }
  }
  return error;
};
