import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ErrorPage from '../components/error/500';

class InternalError extends React.Component {
  render() {
    return <ErrorPage header={true} {...this.props} />;
  }
}

const mapStateToProps = state => ({});
const mapDispatchToProps = {};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(InternalError)
);
