import { Button, makeStyles } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles({
  button: {
    background: '#184AAA',
    borderRadius: '6px',
    color: '#fff',
    fontWeight: 'bold',
    fontSize: '16px',
    lineHeight: '22px',
    padding: '13px',
    width: '100%',
    '&:hover': {
      backgroundColor: '#184AAA',
    },
  },
});

const CustomButton = ({ custom, ...props }) => {
  const classes = useStyles();
  return <Button {...props} className={custom ? classes.button : ''} />;
};

export default CustomButton;
