import React, { useState, useEffect } from 'react';
import * as _ from 'lodash';
import {
  Button,
  TextField,
  FormHelperText,
  FormControl,
} from '@material-ui/core';
import TermAndPrivicyCheckbox from '../common/TermAndPrivicyCheckbox';
import BackButton from '../common/back';

import {
  validateFields,
  checkResponseType,
  statusCodeValidate,
} from '../../utils/validator';
import { successRedirection } from '../common/helper';
import {
  signInWithEmailAndPassword,
  signUpWithEmailAndPassword,
  updateUserDetails,
} from '../firebase/helper';
import NameInput from './NameInput';
import ErrorText from '../common/ErrorText';
import { ERROR_MESSAGES } from '../../utils/constants';

const PasswordInput = props => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState({});
  // const [name, setName] = useState('');
  const [fname, setFname] = useState('');
  const [lname, setLname] = useState('');
  const [terms, setTerms] = useState(false);
  const [requiredFields, setRequiredFields] = useState(
    props.loginMode.required
  );
  useEffect(() => {
    props.setView('password');

    if (props.inviteUser.name) {
      const name = props.inviteUser.name.split(' ');
      setFname(name.splice(0, 1).join(''));
      if (name.length) setLname(name.join(' '));
    }
  }, [props]);
  const validateForm = () => {
    let error = {};
    let isValidate = true;

    let validatePassword = validateFields('password', password);
    if (validatePassword) {
      _.set(error, 'password', validatePassword);
    }

    if (props.signup) {
      if (requiredFields.name) {
        let validateName = validateFields('name', fname);
        if (validateName) {
          _.set(error, 'fname', validateName);
        }
        let validateTcAccept = validateFields('tc_accept', terms);
        if (validateTcAccept) {
          _.set(error, 'tc_accept', validateTcAccept);
        }
      }
      let validatePassword = validateFields('password', confirmPassword);
      if (validatePassword) {
        _.set(error, 'confirmPassword', validatePassword);
      } else if (password != confirmPassword) {
        _.set(error, 'password', ERROR_MESSAGES.invalid_confirm_password);
        _.set(
          error,
          'confirmPassword',
          ERROR_MESSAGES.invalid_confirm_password
        );
      }
    }
    if (!_.isEmpty(error)) {
      isValidate = false;
    }
    setError(error);
    return isValidate;
  };

  const handleSubmit = async function (e) {
    e.preventDefault();
    setFname(fname.trim());
    setLname(lname.trim());
    if (validateForm()) {
      props.startPageLoader();
      try {
        if (props.signup) {
          await signUpWithEmailAndPassword(props.email, password);
          if (fname) await updateUserDetails(`${fname} ${lname}`.trim());
        } else await signInWithEmailAndPassword(props.email, password);
        await successRedirection(props);
      } catch (error) {
        props.showSnackbar(error.message);
        props.stopPageLoader();
        props.backClick();
        return;
      }
    }
  };

  return (
    <>
      <BackButton {...props} />
      <form onSubmitCapture={e => handleSubmit(e)}>
        {props.signup && requiredFields.name && (
          <>
            <NameInput
              fname={fname}
              lname={lname}
              setFname={setFname}
              setLname={setLname}
              error={error}
            />
          </>
        )}
        <div className="row">
          <div className="form-group col-12 col-sm-12 col-md-12 mt-4 mb-1">
            <label className="sk-up-ob-pf-form-lbl">
              Password <span className="sk-red-txt">*</span>{' '}
            </label>
            <TextField
              className="w-100"
              name="password"
              type="password"
              value={password}
              onChange={e => setPassword(e.target.value)}
              error={!_.isEmpty(_.get(error, ['password'], ''))}
              variant="outlined"
            />
            <ErrorText
              error={error}
              name={'password'}
              tooltip={'must have atleast 6 characters'}
            />
          </div>
        </div>
        {props.signup && (
          <>
            <div className="row">
              <div className="form-group col-12 col-sm-12 col-md-12 mt-4 mb-1">
                <label className="sk-up-ob-pf-form-lbl">
                  Confirm Password <span className="sk-red-txt">*</span>{' '}
                </label>
                <TextField
                  className="w-100"
                  type="password"
                  value={confirmPassword}
                  onChange={e => setConfirmPassword(e.target.value)}
                  error={!_.isEmpty(_.get(error, ['confirmPassword'], ''))}
                  variant="outlined"
                />
                <ErrorText
                  error={error}
                  name={'confirmPassword'}
                  tooltip={'must be equal to password'}
                />
              </div>
            </div>
          </>
        )}
        {props.signup && requiredFields.name && (
          <>
            <div className="sk-cm-ob-form-ctnr sk-cm-ob-form-ctnr-margin mt-4 mb-1">
              <div className="position-relative width-100">
                <FormControl className="sk-cm-ob-form-control">
                  <TermAndPrivicyCheckbox
                    color="primary"
                    checked={terms}
                    inputProps={{ 'aria-label': 'tc_accept' }}
                    onChange={e => setTerms(e.target.checked)}
                  />
                </FormControl>
              </div>
              <FormHelperText
                className="ml-3"
                error={!_.isEmpty(_.get(error, ['tc_accept'], ''))}
              >
                {_.get(error, ['tc_accept'], '')}
              </FormHelperText>
            </div>
          </>
        )}
        <div className="text-center">
          <Button
            className="w-100 form-action-button"
            variant="contained"
            color="primary"
            onClick={e => handleSubmit(e)}
          >
            Continue
          </Button>
        </div>
      </form>
    </>
  );
};

export default PasswordInput;
