import React, { useState } from 'react';
import * as _ from 'lodash';
import {
  showSnackbar,
  startPageLoader,
  stopPageLoader,
} from '../../redux/actions/appAction';
import PropTypes from 'prop-types';
import {
  signInWithPopup,
  signOutUser,
  signUpWithEmailAndPassword,
  updateUserDetails,
} from '../firebase/helper';
import { successRedirectionCopy } from '../common/helper';
import { useDispatch } from 'react-redux';
import { ObjectHasKey, payCreateUserMeta } from '../../utils/generic';

import SignupForm from '../modules/Signup/SignupForm';
import VerifyEmailBackDrop from './VerifyEmailBackdrop';
import { resendVerifyEmail } from '../../api';
import { FIREBASE_ERROR_MESSAGES } from '../../utils/constants';

const CreateAccount = ({
  invited,
  testingEnv,
  state,
  dispatch,
  onChangeHandler,
  onCaptchaClickHandler,
  ignoreCaptchaValidation,
  inviteUser,
  ...props
}) => {
  const _dispatch = useDispatch();
  const [showVerifyEmailScreen, setShowVerifyEmailScreen] = useState(false);

  const handleSignUpWithGoogle = async () => {
    _dispatch(startPageLoader());
    try {
      const authRes = await signInWithPopup('google.com');

      if (
        !_.isEmpty(inviteUser) &&
        authRes?.user?.email.toLowerCase() !==
          state.formData.email.toLowerCase()
      ) {
        throw new Error('Please sign in using your invite email address');
      }

      const meta = payCreateUserMeta(
        { type: state.userType, role: state.userRole },
        {
          newsLetterSubscription:
            !!state.formData.newsLetterSubscription.length,
        }
      );

      meta.googleSignIn = true;

      const res = await successRedirectionCopy(props, '', meta);
      _dispatch(stopPageLoader());
    } catch (error) {
      _dispatch(stopPageLoader());
      _dispatch(showSnackbar(error.message));
    }
  };

  const signupWithEmailAndPassword = async e => {
    _dispatch(startPageLoader());
    try {
      const user = await signUpWithEmailAndPassword(
        state.formData.email.toLowerCase(),
        state.formData.password
      );

      await updateUserDetails(state.formData.fullName.trim());

      const meta = payCreateUserMeta(
        { type: state.userType, role: state.userRole },
        {
          newsLetterSubscription:
            !!state.formData.newsLetterSubscription.length,
          companyName: state.formData.companyName,
        }
      );

      meta.isInvited = invited;
      const res = await successRedirectionCopy(
        props,
        '',
        meta,
        state.captchaToken
      );

      _dispatch(stopPageLoader());

      return res;
    } catch (error) {
      _dispatch(showSnackbar(error.message));
      _dispatch(stopPageLoader());
    }
  };
  const handleSignupWithEmailAndPassword = async e => {
    e.preventDefault();
    if (!ObjectHasKey(state.errorData)) {
      try {
        const res = await signupWithEmailAndPassword();
        if (res?.data?.emailVerified === false) {
          setShowVerifyEmailScreen(true);
          return;
        } else {
          if (res?.data?.success) {
            await signOutUser();
            window.location.replace(`${decodeURIComponent(props.redirectURL)}`);
          } else {
            _dispatch(stopPageLoader());
          }
        }
      } catch (error) {
        _dispatch(showSnackbar(error.message));
        _dispatch(stopPageLoader());
      }
    }
  };

  if (showVerifyEmailScreen) {
    return (
      <VerifyEmailBackDrop
        email={state.formData.email}
        onResend={() => {
          _dispatch(startPageLoader());
          resendVerifyEmail(
            state.formData.email,
            props.redirectURL,
            !state.formData.companyName
          )
            .then(() => {
              _dispatch(
                showSnackbar(
                  "Verification email resent successfully"
                )
              );
              _dispatch(stopPageLoader());
            })
            .catch(error => {
              console.log({ error });
              _dispatch(
                showSnackbar(
                  FIREBASE_ERROR_MESSAGES[error.response.data?.code] ||
                    error.response.data?.message ||
                    error.message
                )
              );
              _dispatch(stopPageLoader());
            });
        }}
      />
    );
  }

  return (
    <SignupForm
      state={state}
      onChangeHandler={onChangeHandler}
      onSubmit={handleSignupWithEmailAndPassword}
      onSignupButtonProps={{
        disabled:
          !state.formData.email ||
          !state.formData.password ||
          !(ignoreCaptchaValidation || state.captchaToken) ||
          !!ObjectHasKey(state.errorData) ||
          state?.formData?.newsLetterSubscription?.length === 0,
        id: 'form-signUp-submitCTA',
      }}
      onGoogleSignupButtonProps={{
        onClick: handleSignUpWithGoogle,
        id: 'form-signUp-signUpWithGoogle',
      }}
      onCaptchaClickHandler={onCaptchaClickHandler}
    />
  );
};

CreateAccount.propTypes = {
  state: PropTypes.shape({
    formData: PropTypes.shape({
      newsLetterSubscription: PropTypes.array.isRequired,
    }).isRequired,
  }).isRequired,
};
export default CreateAccount;
