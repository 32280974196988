import React from "react";

function DoneIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="130"
      height="104"
      fill="none"
      viewBox="0 0 130 104"
    >
      <path fill="#fff" d="M0 0H130V104H0z"></path>
      <path
        fill="#EFFAF7"
        fillRule="evenodd"
        d="M32.76 69.681h47.32c.268 0 .529-.029.78-.084.251.055.512.084.78.084h27.04a3.64 3.64 0 000-7.28h-3.12a3.64 3.64 0 010-7.28h9.88a3.64 3.64 0 000-7.28H104a3.64 3.64 0 000-7.28H70.72a3.64 3.64 0 100-7.28H41.08a3.64 3.64 0 000 7.28h-20.8a3.64 3.64 0 100 7.28h13a3.64 3.64 0 110 7.28h-20.8a3.64 3.64 0 100 7.28h20.28a3.64 3.64 0 100 7.28zm84.76 0a3.64 3.64 0 100-7.28 3.64 3.64 0 000 7.28z"
        clipRule="evenodd"
      ></path>
      <path
        stroke="#EFFAF7"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2.5"
        d="M89.7 23.398v3.12M89.7 30.68v3.12M84.5 28.6h3.12M91.78 28.6h3.12M46.54 76.96v2.08M46.54 83.2v2.08M42.38 81.123h2.08M48.62 81.123h2.08"
      ></path>
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M83.69 68.066c.724-.885 1.253-1.691 1.83-2.65a23.705 23.705 0 002.742-6.708 24.023 24.023 0 00-.66-13.436 23.845 23.845 0 00-2.907-5.743 24.197 24.197 0 00-2.517-3.074 24.097 24.097 0 00-4.489-3.653 23.859 23.859 0 00-5.447-2.538 24.046 24.046 0 00-13.013-.46 23.688 23.688 0 00-10.875 6.002 23.784 23.784 0 00-4.491 5.95 23.756 23.756 0 00-2.348 6.651 24.047 24.047 0 00-.29 7.191 23.906 23.906 0 006.597 14.03 23.952 23.952 0 008.132 5.531 24.035 24.035 0 0011.368 1.698c3.222-.3 5.862-.72 8.772-2.33"
        clipRule="evenodd"
      ></path>
      <path
        stroke="#13B68F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3.5"
        d="M83.69 68.066c.724-.885 1.253-1.691 1.83-2.65a23.705 23.705 0 002.742-6.708 24.023 24.023 0 00-.66-13.436 23.845 23.845 0 00-2.907-5.743 24.197 24.197 0 00-2.517-3.074 24.1 24.1 0 00-4.489-3.653 23.859 23.859 0 00-5.447-2.538 24.046 24.046 0 00-13.013-.46 23.688 23.688 0 00-10.875 6.002 23.784 23.784 0 00-4.491 5.95 23.756 23.756 0 00-2.348 6.651 24.047 24.047 0 00-.29 7.191 23.906 23.906 0 006.597 14.03 23.952 23.952 0 008.132 5.531 24.035 24.035 0 0011.368 1.698c3.222-.3 5.862-.72 8.772-2.33"
      ></path>
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M79.125 72.371c.857-.551 1.418-1.038 1.913-1.593l-1.913 1.593z"
        clipRule="evenodd"
      ></path>
      <path
        stroke="#13B68F"
        strokeLinecap="round"
        strokeWidth="3.5"
        d="M79.125 72.371c.857-.551 1.418-1.038 1.913-1.593"
      ></path>
      <path
        fill="#EFFAF7"
        d="M86.84 54.4a25.58 25.58 0 01-.447.004c-13.132 0-23.884-10.238-24.785-23.205C51.155 32.848 43.16 41.94 43.16 52.906c0 12.135 9.79 21.973 21.865 21.973 11.576 0 21.05-9.04 21.815-20.479z"
      ></path>
      <path
        fill="#13B68F"
        d="M61.2 58.24a2 2 0 01-2.837 0l-4.282-4.308a1.62 1.62 0 00-2.297 2.283l6.58 6.62a2 2 0 002.836 0l17.016-17.12a1.62 1.62 0 00-2.297-2.282L61.2 58.24z"
      ></path>
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M57.628 33.494c-1.014.38-2.004.843-2.961 1.387a21.025 21.025 0 00-5.665 4.722m-1.43 1.912a20.655 20.655 0 00-1.65 3.011"
        clipRule="evenodd"
      ></path>
      <path
        fill="#EFFAF7"
        d="M58.067 34.664a1.25 1.25 0 10-.878-2.34l.878 2.34zm-3.4.217l.618 1.087-.618-1.087zm-4.18 3.133l-.869-.9.869.9zm-2.441.784a1.25 1.25 0 001.912 1.61l-1.912-1.61zm.569 3.407a1.25 1.25 0 10-2.085-1.38l2.084 1.38zm-2.057 1.015l1.103.587-1.103-.587zm-1.779.798a1.25 1.25 0 102.284 1.016l-2.284-1.016zm12.41-11.694a21.93 21.93 0 00-3.14 1.47l1.236 2.174c.899-.512 1.83-.946 2.782-1.304l-.878-2.34zm-3.14 1.47a22.112 22.112 0 00-4.43 3.32l1.736 1.799a19.611 19.611 0 013.93-2.945l-1.236-2.173zm-4.43 3.32a22.328 22.328 0 00-1.573 1.684l1.912 1.61a19.8 19.8 0 011.397-1.495l-1.737-1.798zm-3.09 3.711c-.389.589-.748 1.192-1.075 1.809l2.207 1.173c.29-.546.608-1.08.953-1.602l-2.084-1.38zm-1.075 1.809c-.242.455-.467.916-.675 1.384l2.284 1.016c.185-.414.384-.824.598-1.227l-2.207-1.173z"
      ></path>
    </svg>
  );
}

export default DoneIcon;
