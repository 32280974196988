import React from 'react';
import { Box, Grid, Container, styled } from '@material-ui/core';
import Header from '../Header';
import PayAuthBgImg from '../../../images/pay-auth-bg-img.png';

const BoxWithBackground = styled(Box)(({ theme }) => {
  return {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    height: '100%',
    backgroundImage: `url(${PayAuthBgImg})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'right',
    paddingRight: theme.spacing(10),
    [theme.breakpoints.down('sm')]: {
      backgroundImage: 'none',
      paddingRight: 0,
    },
     backgroundSize: "contain"
  };
});

const StyledGrid = styled(Grid)(({ theme }) => {
  return {
    [theme.breakpoints.down('sm')]: {
      gap: theme.spacing(4),
    },
  };
});

const PayLayout = ({ LeftGridItem, RightGridItem }) => {
  return (
    <Box bgcolor="#FEFAFA" minHeight="100vh" paddingBottom={5}>
      <Header />
      <Container maxWidth="lg">
        <StyledGrid container>
          <Grid item xs={12} sm={12} md={6}>
            <BoxWithBackground>{LeftGridItem}</BoxWithBackground>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            {RightGridItem}
          </Grid>
        </StyledGrid>
      </Container>
    </Box>
  );
};

export default PayLayout;
