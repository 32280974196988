// import React, { useState } from 'react';
// import * as _ from 'lodash';
// import rightArrow from '../../images/back_arrow.svg';
// import Img from './Img';

// const BackButton = props => {

//   return (
//     <>
//       <div className="position-absolute sk-back-btn cursor-p ml-lg-0">
//         <Img
//           src={rightArrow}
//           className="w-100"
//           onClick={() => props.backClick()}
//         />
//       </div>
//     </>
//   );
// };

// export default BackButton;

import React from 'react';
import { makeStyles } from '@material-ui/core';
import { Button } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

const useStyles = makeStyles({
  backIcon: {
    color: '#000',
    padding: '0 5px 0',
  },
  text: {
    fontSize: '15px',
    lineHeight: '20px',
    color: '#06132D',
    marginLeft: '14px',
  },
});

const BackButton = ({ handleClick, text }) => {
  const classes = useStyles();
  return (
    <Button onClick={handleClick} startIcon={<ArrowBackIcon />}>
      {text}
    </Button>
  );
};

export default BackButton;
