import React, { useState } from 'react';
import { InputAdornment, IconButton } from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import StandardTextField from '../../designSystem/StandardTextField';

const PasswordInput = ({ ...rest }) => {
  const [previewPassword, setPreviewPassword] = useState(false);
  return (
    <StandardTextField
      {...rest}
      type={previewPassword ? 'text' : 'password'}
      endAdornment={
        <InputAdornment position="start">
          <IconButton
            size="small"
            aria-label="toggle password visibility"
            onClick={() => {
              setPreviewPassword(password => !password);
            }}
            edge="end"
          >
            {previewPassword ? (
              <Visibility fontSize="small" />
            ) : (
              <VisibilityOff fontSize="small" />
            )}
          </IconButton>
        </InputAdornment>
      }
    />
  );
};

export default PasswordInput;
