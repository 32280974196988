const fontRatio = 16;

const style = (
  fontSize,
  fontWeight = 400,
  lineHeight = 1.333,
  letterSpacing
) => {
  // TODO: handle media queries
  const properties = {
    ...(fontWeight && { fontWeight }),
    ...(letterSpacing && { letterSpacing }),
  };
  properties.fontSize = `${fontSize / fontRatio}rem`;
  properties.lineHeight = lineHeight / fontSize;

  return properties;
};

export const themeConstants = {
  palette: {
    primary: {
      main: '#13B68F',
      dark: '#0F9272',
      contrastText: '#fff',
    },
    secondary: {
      main: '#FE7360',
      contrastText: '#fff',
    },
    info: {
      main: '#777777',
      light: '#E5E5E5',
      contrastText: '#fff',
    },
    warning: {
      main: '#F3A54A',
      light: '#FDF4E7',
      contrastText: '#fff',
    },
    success: {
      main: '#59B0EF',
      light: '#E8F5FC',
      contrastText: '#fff',
    },
    error: {
      main: '#E16D65',
      light: '#FAEEED',
      contrastText: '#fff',
    },
    text: {
      main: '#333333',
      primary: '#777777',
      disabled: '#BBBBBB',
      default: '#353D4A',
    },
    background: {
      paper: '#fff',
      default: '#fff',
      primary: '#FEFAFA',
      secondary: '#F2F3F7',
      bodyColor: '#fefafa',
    },
    common: {
      black: '#000',
      white: '#fff',
    },
    grey: {
      50: '#F6F6F6',
      100: '#E5E5E5',
      200: '#BBBBBB',
      300: '#777777',
      400: '#333333',
    },
  },
  typography: {
    fontFamily: "'Inter', sans-serif",
    fontSize: 16,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightSemiBold: 600,
    fontWeightBold: 700,
    fontWeightExtraBold: 800,
    h1: style(64, 600, 76, '-0.005em'),
    h2: style(44, 600, 70),
    h3: style(32, 600, 52, '0.0025em'),
    h4: style(24, 600, 42),
    subtitle1: style(20, 600, 30, '0.0015em'),
    body1: style(16, 400, 24, '0.0015em'),
    body2: style(14, 400, 22, '0.0015em'),
    caption: style(12, 400, 18, '0.004em'),
  },
  shadows: [
    'none',
    '2px 2px 8px rgba(51, 51, 51, 0.15)',
    '2px 3px 10px rgba(51, 51, 51, 0.2)',
    '0px 3px 3px -2px rgba(0,0,0,0.2),0px 3px 4px 0px rgba(0,0,0,0.14),0px 1px 8px 0px rgba(0,0,0,0.12)',
    '0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)',
    '0px 3px 5px -1px rgba(0,0,0,0.2),0px 5px 8px 0px rgba(0,0,0,0.14),0px 1px 14px 0px rgba(0,0,0,0.12)',
    '0px 3px 5px -1px rgba(0,0,0,0.2),0px 6px 10px 0px rgba(0,0,0,0.14),0px 1px 18px 0px rgba(0,0,0,0.12)',
    '0px 4px 5px -2px rgba(0,0,0,0.2),0px 7px 10px 1px rgba(0,0,0,0.14),0px 2px 16px 1px rgba(0,0,0,0.12)',
    '0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12)',
    '0px 5px 6px -3px rgba(0,0,0,0.2),0px 9px 12px 1px rgba(0,0,0,0.14),0px 3px 16px 2px rgba(0,0,0,0.12)',
    '0px 6px 6px -3px rgba(0,0,0,0.2),0px 10px 14px 1px rgba(0,0,0,0.14),0px 4px 18px 3px rgba(0,0,0,0.12)',
    '0px 6px 7px -4px rgba(0,0,0,0.2),0px 11px 15px 1px rgba(0,0,0,0.14),0px 4px 20px 3px rgba(0,0,0,0.12)',
    '0px 7px 8px -4px rgba(0,0,0,0.2),0px 12px 17px 2px rgba(0,0,0,0.14),0px 5px 22px 4px rgba(0,0,0,0.12)',
    '0px 7px 8px -4px rgba(0,0,0,0.2),0px 13px 19px 2px rgba(0,0,0,0.14),0px 5px 24px 4px rgba(0,0,0,0.12)',
    '0px 7px 9px -4px rgba(0,0,0,0.2),0px 14px 21px 2px rgba(0,0,0,0.14),0px 5px 26px 4px rgba(0,0,0,0.12)',
    '0px 8px 9px -5px rgba(0,0,0,0.2),0px 15px 22px 2px rgba(0,0,0,0.14),0px 6px 28px 5px rgba(0,0,0,0.12)',
    '0px 8px 10px -5px rgba(0,0,0,0.2),0px 16px 24px 2px rgba(0,0,0,0.14),0px 6px 30px 5px rgba(0,0,0,0.12)',
    '0px 8px 11px -5px rgba(0,0,0,0.2),0px 17px 26px 2px rgba(0,0,0,0.14),0px 6px 32px 5px rgba(0,0,0,0.12)',
    '0px 9px 11px -5px rgba(0,0,0,0.2),0px 18px 28px 2px rgba(0,0,0,0.14),0px 7px 34px 6px rgba(0,0,0,0.12)',
    '0px 9px 12px -6px rgba(0,0,0,0.2),0px 19px 29px 2px rgba(0,0,0,0.14),0px 7px 36px 6px rgba(0,0,0,0.12)',
    '0px 10px 13px -6px rgba(0,0,0,0.2),0px 20px 31px 3px rgba(0,0,0,0.14),0px 8px 38px 7px rgba(0,0,0,0.12)',
    '0px 10px 13px -6px rgba(0,0,0,0.2),0px 21px 33px 3px rgba(0,0,0,0.14),0px 8px 40px 7px rgba(0,0,0,0.12)',
    '0px 10px 14px -6px rgba(0,0,0,0.2),0px 22px 35px 3px rgba(0,0,0,0.14),0px 8px 42px 7px rgba(0,0,0,0.12)',
    '0px 11px 14px -7px rgba(0,0,0,0.2),0px 23px 36px 3px rgba(0,0,0,0.14),0px 9px 44px 8px rgba(0,0,0,0.12)',
    '0px 11px 15px -7px rgba(0,0,0,0.2),0px 24px 38px 3px rgba(0,0,0,0.14),0px 9px 46px 8px rgba(0,0,0,0.12)',
  ],
};
