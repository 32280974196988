import { FormControl, styled } from '@material-ui/core';

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  '& label+.MuiInput-root': {
    marginTop: theme.spacing(1),
  },
  '& label+.MuiFormControl-root': {
    marginTop: theme.spacing(1),
  },
}));

export default StyledFormControl;
