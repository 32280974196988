import { ERROR_MESSAGES, ScreenType, USER_ROLE } from './constants';
import * as EmailValidator from 'email-validator';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { passwordValidations } from './generic';

export const validateFields = (key, value, rest = {}) => {
  let error = '';
  switch (key) {
    case 'name':
      if (!value) {
        error = ERROR_MESSAGES.name;
      }
      break;
    case 'companyName':
      if (!value) {
        error = ERROR_MESSAGES.companyName;
      }
      break;
    case 'email':
      if (!value) {
        error = ERROR_MESSAGES.email;
      } else if (!EmailValidator.validate(value)) {
        error = ERROR_MESSAGES.invalid_email;
      } else if (rest?.role === USER_ROLE.Company) {
        const restrictedProviders = JSON.parse(
          process.env.REACT_APP_RESTRICTED_FREE_EMAIL_PROVIDERS
        );
        if (
          restrictedProviders.find(provider =>
            value.toLowerCase().endsWith(provider)
          )
        ) {
          error = ERROR_MESSAGES.workEmail;
        }
      }
      break;
    case 'password':
      value = value.trim();
      if (!value) {
        if (rest?.screen === ScreenType.SignIn) {
          error = ERROR_MESSAGES.empty_password;
        }
      } else if (rest?.screen !== ScreenType.SignIn) {
        for (const item of passwordValidations) {
          const { message, validator } = item;
          if (!validator.test(value)) {
            error = message;
            break;
          }
        }
      }

      break;
    case 'phone':
      if (!value || value === '+') {
        error = ERROR_MESSAGES.phone;
      }
      const phoneNumber = parsePhoneNumberFromString(value);
      if (!phoneNumber || !phoneNumber.isValid()) {
        error = ERROR_MESSAGES.invalid_phone;
      }
      break;
    case 'otp':
      if (typeof value === 'string' && value.length !== 6) {
        error = ERROR_MESSAGES.otp;
      } else {
        if (value.code === 'auth/invalid-verification-code') {
          error = ERROR_MESSAGES.invalid_otp;
        } else {
          error = value.message;
        }
      }
      break;
    case 'tc_accept':
      if (value !== true) {
        error = ERROR_MESSAGES.tc_and_privicy_policy;
      }
      break;
    case 'geography':
      if (!value) {
        error = 'Country is required';
      }
      break;
    default:
      break;
  }
  return error;
};

export const checkResponseType = (type, token, redirect_url, props) => {
  switch (type) {
    case 'data':
      props.setRootView && props.setRootView(false);
      props.setOTPView && props.setOTPView(false);
      props.setEmailView && props.setEmailView(true);
      break;
    case 'mobile':
      props.setRootView && props.setRootView(false);
      props.setOTPView && props.setOTPView(false);
      props.setMobileView && props.setMobileView(true);
      break;
    case 'redirect':
      if (token)
        window.location.assign(
          `${decodeURIComponent(redirect_url)}?token=${token}`
        );
      else window.location.assign(`${decodeURIComponent(redirect_url)}`);
      break;
    default:
      props.setRootView && props.setRootView(true);
      props.setEmailView && props.setEmailView(false);
      props.setMobileView && props.setMobileView(false);
      props.setOTPView && props.setOTPView(false);
      break;
  }
};

export const statusCodeValidate = (status, history) => {
  switch (status) {
    case 200:
      break;
    case 404:
      history.push('/404');
      break;
    default:
      history.push('/500');
  }
};
