import { useEffect } from 'react';

const useTitle = title => {
  useEffect(() => {
    if (title) {
      document.title = title;
    } else {
      try {
        const path = window.location.pathname?.split('/')?.[1];
        if (path) {
          document.title = path.split('-').join(' ');
        }
      } catch (error) {}
    }
  }, [title]);
};

export default useTitle;
